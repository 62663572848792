.form {
  margin: space(l) auto;

  &__grid {
    @include get-grid(
      (
        default: 1,
        s: 1,
        m: 4
      ),
      false
    );

    grid-row-gap: space(m);
  }

  hr {
    height: 1px;
    width: 100%;
    background: color(neutral, 50);
    border: none;
    outline: none;
  }

  &__item--full,
  &__error,
  &__submit {
    @include mq(m) {
      grid-column: span 4;
    }
  }

  &__item--half {
    @include mq(m) {
      grid-column: span 2;
    }
  }

  &__item--third {
    @include mq(m) {
      grid-column: span 1;
    }
  }

  &__item--two-third {
    @include mq(m) {
      grid-column: span 3;
    }
  }

  &__submit {
    display: flex;
    justify-content: flex-end;
  }

  .checkbox,
  .radio {
    margin-top: space(m);
  }

  &__error {
    display: none;
    color: color(primary);
  }

  &--error &__error {
    display: block;
  }

  &--has-grid {
    margin: 0;

    @include get-grid;

    .form__grid {
      grid-column: 1 / span 6;

      @include mq(s) {
        grid-column: 2 / span 4;
      }

      @include mq(m) {
        grid-column: 3 / span 7;
      }
    }
  }
}
