.survey-nav-button {
  color: color(primary);

  &:hover {
    color: color(primary, 70);
  }

  &:active {
    color: color(primary, 90);
  }

  &__icon {
    background: color(neutral, 10);
    border-radius: 40px;

    @include mq(m) {
      margin-bottom: space(xs);
    }
  }

  &__label {
    text-transform: uppercase;
    white-space: pre-line; // respect \n as line breaks

    @include mq($until: m) {
      @include hide-visually();
    }
  }
}
