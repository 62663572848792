$gridConfigs: (
  default: (
    columns: 6,
    columnGap: 30px
  ),
  s: (
    columns: 6,
    columnGap: 30px
  ),
  m: (
    columns: 12,
    columnGap: 30px
  ),
  l: (
    columns: 12,
    columnGap: 30px
  )
);

$gridMaxWith: 1680px;
