.footer {
  display: relative;
  background: color(neutral, 90);
  color: color(neutral, 10);
  padding: space(l) 0;

  ul {
    list-style: none;
    padding: 0;
  }

  @include mq(m) {
    padding: space(xl) 0;
  }

  &__wrapper {
    position: relative;
    @include grid-container();
  }

  &__subline {
    margin-top: space(m);
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    border-bottom: 1px solid color(neutral, 10);
    margin-bottom: space(l);
    padding: 0 30px space(l) 30px;

    @include mq(768px) {
      flex-direction: row;
    }

    @include mq(m) {
      margin: 0 space(s) space(xl) space(s);
      padding: 0 9% space(xl) 9%;
    }
  }

  // SOCIAL BLOCK START (LINKS & TEASER)
  &__social-links,
  &__teaser {
    width: 100%;

    @include mq(768px) {
      width: 50%;
    }

    &:nth-child(1) {
      @include mq(768px) {
        padding-right: 9%;
      }
    }

    &:nth-child(2) {
      border-top: 1px solid color(neutral, 10);
      margin-top: space(l);
      padding-top: space(l);

      @include mq(768px) {
        border-top: none;
        border-left: 1px solid color(neutral, 10);
        margin-top: 0;
        padding-top: 0;
        padding-left: 9%;
      }
    }
  }

  &__teaser a {
    margin-top: space(m);
  }

  &__social-links-list {
    display: flex;
    flex-wrap: wrap;
    margin: space(m) 0 0;
    padding: 0;

    > li {
      margin-right: space(s);
      margin-bottom: space(s);

      a {
        display: block;
      }
    }
  }
  // SOCIAL BLOCK END

  // PARTNER LOGOS START
  &__partner-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    @include mq(768px) {
      flex-direction: row;
    }

    > * {
      @include mq(768px) {
        width: 50%;
      }
    }
  }

  &__partner-content &__subline {
    @include mq(768px) {
      padding-right: 9%;
    }
  }

  &__partner-logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: space(m) 0 0;
  }

  &__partner-logos li {
    position: relative;
    max-width: 200px;
    margin-right: space(l);
    margin-bottom: space(m);
  }

  &__partner-logo {
    position: relative;
    display: block;
    filter: saturate(0);
    transition: filter 0.2s ease;

    &:hover {
      filter: none;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  // PARTNER LOGOS END

  // NAV START
  &__nav {
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    @include get-grid-outer-gaps();

    @include mq(768px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include mq(m) {
      flex-wrap: nowrap;
      margin: 0 9%;
    }
  }

  &__logo {
    margin-bottom: space(m);
    width: 122px;
    height: 57px;

    @include mq(768px) {
      width: 100%;
      margin-bottom: 0;
    }

    @include mq(m) {
      width: 174px;
      height: 77px;
      flex-shrink: 0;
      margin-left: -30px;
      margin-right: space(l);
    }

    svg {
      width: 122px;
      height: 57px;

      @include mq(m) {
        width: 174px;
        height: 77px;
      }
    }
  }

  &__links-toplink {
    margin-bottom: space(xs);

    @include mq(768px) {
      margin-bottom: space(s);
    }
  }

  &__links {
    width: 100%;
    margin-top: space(m);

    @include mq(768px) {
      max-width: 33%;
    }

    @include mq(m) {
      max-width: 280px;
      margin-top: 0;
    }
  }

  &__link-list {
    margin: 0;
    display: flex;
    flex-direction: column;

    li + li {
      margin-top: space(xs);
    }
  }

  // NAV END
}
