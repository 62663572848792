.breadcrumb {
  position: relative;
  width: 100%;

  &--hidden {
    @include hide-visually();
  }

  ol {
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
  }

  li {
    display: flex;
    // A very high flex-shrink prevents the last-child (with flex-shrink: 1)
    // from shrinking until it is really necessary.

    flex: 0 99999 auto;
    // Min width prevents the collapsed items from disappearing completely
    // With this there will always be a "... >" at least.

    min-width: 7px;
    align-items: center;

    &:last-child {
      flex: 0 1 auto;
      pointer-events: none;
      @include hide-visually();
    }

    & + li:before {
      display: inline-block;
      margin: 0 0.25em;
      transform: rotate(15deg);
      border-right: 0.1em solid currentColor;
      height: 0.8em;
      content: '';
    }
  }

  a {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 0 1 auto;
  }

  &__toggle {
    appearance: none;
    border: none;
    background: transparent;
    padding: 0;
  }

  &__layer {
    transform: scale(0);
    transform-origin: center;
    visibility: hidden;
    transition: visibility 0ms linear 200ms, transform 200ms ease;
    width: auto;
    min-width: 260px;
    top: -60px !important;

    &[aria-hidden='false'] {
      transform: scale(1);
      visibility: visible;
      transition: visibility 0ms linear, transform 200ms ease;
    }

    li + li:before {
      display: none;
    }
  }
}
