.richtext {
  li,
  p,
  div {
    @include typography(copy);
  }

  h2 {
    @include typography(headline-3);
  }

  h3 {
    @include typography(headline-4);
  }

  h4 {
    @include typography(headline-5);
  }

  strong,
  b {
    font-weight: 500;
  }

  p {
    margin-top: 0;
    margin-bottom: space(m);
  }

  h2,
  h3,
  h4 {
    margin-top: 0;
    margin-bottom: space(m);
  }

  .leadin {
    padding-left: space(s);
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background: color(primary);

      @include mq(s) {
        left: -15%;
      }
    }

    @include mq(m) {
      padding-left: 0;
    }

    p {
      @include typography(leadin);

      margin-bottom: 0;
    }
  }

  .small-paragraph {
    @include typography(copy-small);
  }

  /* stylelint-disable */
  ul {
    list-style-type: none;

    li::before {
      content: '';
      width: 6px;
      height: 6px;
      background: color(primary);
      border-radius: 50%;
      top: 10px;

      @include mq(m) {
        top: 12px;
      }
    }
  }

  ol {
    counter-reset: number;
    list-style-type: none;

    li::before {
      counter-increment: number;
      content: counter(number) '';
    }

    ol {
      list-style-type: none;
      counter-reset: number;

      li::before {
        counter-increment: number;
        content: counter(number, lower-alpha) '';
      }

      ol {
        list-style-type: square;
      }
    }
  }

  ul,
  ol {
    margin-top: 0;
    margin-bottom: space(m);
    padding: 0;

    li {
      position: relative;
      margin-bottom: space(xs);
      padding-left: space(m);

      &:before {
        color: color(primary);
        font-size: 14px;
        line-height: var(--copy-line-height);
        font-weight: 700;
        font-family: $font-family-slab;

        position: absolute;
        left: 0;
      }
    }

    li > ul,
    li > ol {
      margin-bottom: 0;
    }
  }

  a {
    display: inline-block;
    position: relative;
    text-decoration: underline;
    text-decoration-thickness: from-font;

    &:hover {
      color: color(primary);
    }

    &.text--button {
      text-decoration: none;
      text-transform: uppercase;
      color: color(primary);
      display: inline-flex;
      align-items: center;

      .text--button-icon {
        display: inline;
        white-space: nowrap;
        position: relative;
        font-size: 0;
      }

      & .text--button-icon:after {
        content: '';
        display: inline-block;
        width: 73px;
        height: 20px;
        margin-left: space(xs);
        vertical-align: middle;
        background-image: url('data:image/svg+xml,<svg width="73" height="20" xmlns="http://www.w3.org/2000/svg" fill="%23e30613"><g fill-rule="evenodd"><path d="M64.933 14.5L73 10.562 64.933 6.5z"/><path fill-rule="nonzero" d="M66.244 10v1H.4v-1z"/></g></svg>');
      }

      &:hover .text--button-icon:after {
        background-image: url('data:image/svg+xml,<svg width="73" height="20" xmlns="http://www.w3.org/2000/svg" fill="%23b5040f"><g fill-rule="evenodd"><path d="M64.933 14.5L73 10.562 64.933 6.5z"/><path fill-rule="nonzero" d="M66.244 10v1H.4v-1z"/></g></svg>');
      }

      &:hover {
        color: color(primary, 70);
      }

      &[target='_blank'] {
        & .text--button-icon:after {
          content: '';
          display: inline-block;
          width: 24px;
          height: 24px;
          margin-left: 0px;
          vertical-align: middle;
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M17.873 6.116L15 14.556l-2.45-2.45-5.794 5.769-.693-.695 5.793-5.767L9.343 8.9l8.53-2.784z' fill='%23e30613'/%3E%3C/svg%3E");
        }

        &:hover .text--button-icon:after {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M17.873 6.116L15 14.556l-2.45-2.45-5.794 5.769-.693-.695 5.793-5.767L9.343 8.9l8.53-2.784z' fill='%23b5040f'/%3E%3C/svg%3E");
        }
      }
    }
  }

  a[target='_blank'] {
    .external-link-icon {
      display: inline;
      white-space: nowrap;
      position: relative;
    }

    & .external-link-icon:after {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      vertical-align: middle;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M17.873 6.116L15 14.556l-2.45-2.45-5.794 5.769-.693-.695 5.793-5.767L9.343 8.9l8.53-2.784z' fill='%234a4a4a'/%3E%3C/svg%3E");
    }

    &:hover .external-link-icon:after {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M17.873 6.116L15 14.556l-2.45-2.45-5.794 5.769-.693-.695 5.793-5.767L9.343 8.9l8.53-2.784z' fill='%23e30613'/%3E%3C/svg%3E");
    }
  }

  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }

  &--has-grid {
    @include get-grid;
  }

  &--has-grid > * {
    grid-column: 1 / span 6;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 3 / span 7;
    }
  }

  &--has-grid > ul,
  &--has-grid > ol {
    grid-column: 1 / span 6;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 4 / span 6;
    }
  }
}
/* stylelint-enable */
