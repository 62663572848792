@import './javascripts/components/SearchHits/search-hits';
@import './javascripts/components/SearchNoResults/search-no-results';
@import './javascripts/components/Consent/consent';
@import './javascripts/components/Autocomplete/autocomplete';

.search {
  position: relative;
  width: 100%;

  &__extended-search-link {
    display: flex;
    justify-content: center;
    margin: space(xs) 0;
  }
}
