.content-image {
  width: 100%;

  & > div {
    background: color(neutral, 5);
    width: 100%;
  }

  figcaption {
    margin-top: space(s);
  }

  &--has-grid {
    @include get-grid();

    &.content-image--landscape {
      & > div {
        grid-column: 1 / span 6;

        @include mq(s) {
          grid-column: 1 / span 5;
        }

        @include mq(m) {
          grid-column: 2 / span 8;
        }
      }

      figcaption {
        grid-column: 2 / span 5;

        @include mq(s) {
          grid-column: 2 / span 4;
        }

        @include mq(m) {
          grid-column: 4 / span 6;
        }
      }
    }

    &.content-image--portrait {
      & > div {
        grid-column: 2 / span 5;

        @include mq(s) {
          grid-column: 2 / span 3;
        }

        @include mq(m) {
          grid-column: 4 / span 4;
        }
      }

      figcaption {
        grid-column: 2 / span 5;

        @include mq(s) {
          grid-column: 2 / span 4;
        }

        @include mq(m) {
          grid-column: 4 / span 6;
        }
      }
    }
  }
}
