.facet-filter-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: space(s);

  input {
    @include hide-visually();
  }

  &__custom-checkbox {
    display: inline-flex;
    flex-wrap: nowrap;
    text-transform: uppercase;

    border-radius: 13px;
    border: 1px solid color(neutral, 90);
    min-height: 26px;
    text-align: left;
    align-items: center;
    cursor: pointer;

    background: none;
    color: color(neutral, 90);

    span {
      padding: 2px space(s);
    }

    input:hover + & {
      color: color(neutral, 10);
      background: color(primary, 70);
      border-color: color(primary, 70);
    }

    input:active + & {
      color: color(neutral, 10);
      background: color(primary, 90);
      border-color: color(primary, 90);
    }

    input:focus-visible + & {
      color: color(neutral, 10);
      background: color(interaction);
      border-color: color(interaction);
      outline: 0;
    }

    input:disabled + & {
      color: color(neutral, 10);
      background: color(neutral, 50);
    }

    input[disabled] {
      color: color(neutral, 10);
      background: color(neutral, 50);
      border-color: color(neutral, 50);
    }

    input:checked + & {
      color: color(neutral, 10);
      background: color(neutral, 90);
    }
  }
}
