.main-page {
  position: relative;

  .main-stage + &__content .teaser-focus-filter {
    margin-top: -30px;
  }

  &__content {
    margin: 0 auto;
    max-width: 1380px;
    width: 100%;
  }
}
