.dialog {
  bottom: 0px;
  left: 0px;
  overflow-y: auto;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: get-z-index('dialog');

  @include mq(m) {
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.3);
  }

  &__wrapper {
    display: flex;
    max-width: 100%;
    min-height: 100%;

    @include mq(m) {
      align-items: center;
      margin: space(l) auto;
      max-width: 768px;
      min-height: calc(100vh - (2 * #{space(l)}));
    }

    /* workaround for flexbugs #3 (IE does not align-items with min-height) */
    &::before {
      @include mq(m) {
        content: '';
        display: block;
        height: calc(100vh - (2 * #{space(l)}));
      }
    }
  }

  &__content {
    background: color(neutral, 10);
    border-radius: 40px;
    flex: 1 1 0%;
    padding: space(m);
    position: relative;
  }

  &__close {
    padding: space(xs);
    position: absolute;
    right: space(m);
    top: space(m);

    .svg-sprite {
      display: block;
      height: 20px;
      width: 20px;
    }
  }
}
