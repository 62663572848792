.main-stage {
  color: color(neutral, 10);
  height: 534px;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  @include mq(m) {
    user-select: auto;
    height: 720px;
  }

  &__slides {
    height: 100%;
    position: relative;
    width: 100%;
  }

  &__slide {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__image {
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    overflow: hidden;

    img,
    video {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    &:after {
      background-image: linear-gradient(161deg, #fff 18%, #4a4a4a 80%);
      content: '';
      height: 100%;
      left: 0;
      mix-blend-mode: multiply;
      opacity: 0.8;
      position: absolute;
      top: 0;
      width: 100%;

      @include mq(m) {
        background-image: linear-gradient(278deg, #fff 28%, #4a4a4a 75%);
      }
    }

    &--animate img {
      animation: zoomIn 3s linear infinite alternate 1s;
    }

    img {
      object-position: var(--focalPoint);

      .main-stage--paused & {
        animation-play-state: paused;
      }
    }
  }

  &__slides-count {
    position: absolute;
    z-index: 2;
    top: 26%;
    left: 30px;

    @include mq(m) {
      top: 32%;
      left: 9%;
    }
  }

  &__slide-button {
    background: rgba(255, 255, 255, 0.5);
    border: 0 none;
    border-radius: 4px;
    cursor: pointer;
    height: 8px;
    margin-right: space(xs);
    overflow: hidden;
    padding: 0;
    position: relative;
    width: 54px;
    transform: translateZ(0);

    &-indicator {
      display: block;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(226, 7, 20, 1) 100%
      );
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: scaleX(0);
      transform-origin: left;
      width: 100%;
    }

    @include mq(m) {
      margin-right: space(s);
      width: 128px;
    }

    &--active &-indicator {
      animation: fillIndicator 6s linear 1s;
    }

    .main-stage--paused &-indicator {
      animation-play-state: paused;
    }
  }

  &__text {
    @include get-grid();

    align-content: start;
    margin-top: 150px;
    position: relative;

    @include mq(m) {
      margin-top: 240px;
    }

    > * {
      grid-column: 1 / span 6;

      @include mq(m) {
        grid-column: 2 / span 6;
      }
    }
  }

  &__headline {
    clip-path: inset(0 100% 0 0);
    margin-top: space(l);
    position: relative;

    @include mq(m) {
      margin-top: space(xl);
    }
  }

  &__subline {
    clip-path: inset(0 100% 0 0);
    margin-top: space(s);
  }

  &__links {
    clip-path: inset(0 100% 0 0);
    display: flex;
    flex-direction: column;
    margin-top: space(m);

    @include mq(m) {
      flex-direction: row;
      margin-top: space(l);
    }
  }

  &__link + &__link {
    margin-top: space(s);
    @include mq(m) {
      margin-top: 0;
      margin-left: space(l);
    }
  }
}

@keyframes fillIndicator {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}
