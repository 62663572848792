.consent {
  position: absolute;
  inset: 0;
  background: color(neutral, 10);
  padding: space(xl) space(m);

  h2 {
    color: color(primary);
    text-align: center;
    padding: 0 space(m);
    margin-bottom: space(m);
  }

  &__action {
    margin-top: space(m);
    text-align: center;
  }
}
