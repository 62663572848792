@function get-typography($key, $map: $typography) {
  @return map-get($map, $key);
}

@mixin typography($name) {
  $map: get-typography($name);
  $weight: map-get($map, 'weight');
  $family: map-get($map, 'family');

  font-display: swap;
  font-family: $family;
  font-size: var(--#{$name}-font-size);
  font-weight: $weight;
  letter-spacing: var(--#{$name}-letter-spacing);
  line-height: var(--#{$name}-line-height);
}
