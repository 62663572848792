.survey-columns {
  @include get-grid(
    (
      default: 6,
      m: 8
    ),
    false
  );

  &__col {
    grid-column: 1 / -1;
    min-width: 0; // Prevent Grid Blowout. See https://css-tricks.com/preventing-a-grid-blowout/

    @include mq(m) {
      grid-column: var(--grid-column);
    }

    &:first-child {
      position: relative;

      @include mq($until: m) {
        margin-bottom: space(s);
      }

      &::after {
        --border: 1px #{color(neutral)} solid;

        content: '';

        @include mq($until: m) {
          border-bottom: var(--border);
          display: block;
          margin-top: space(m);
          width: 50%;
        }

        @include mq(m) {
          position: absolute;
          border-right: var(--border);
          height: 100%;
          right: calc(-1 * 30px / 2);
          top: 0;
        }
      }
    }
  }
}
