.search-page {
  position: relative;

  main {
    @include grid-container();
  }

  &__search,
  &__content {
    margin: 0 auto;
    max-width: 1380px;
    width: 100%;
  }

  &__search {
    @include get-grid;

    & > * {
      grid-column: 1 / span 6;

      @include mq(m) {
        grid-column: 2 / span 10;
      }
    }
  }
}
