.member-stage {
  position: relative;
  max-width: 1380px;
  margin: 0 auto;
  margin-bottom: space(l);

  @include get-grid();

  @include mq(m) {
    margin-bottom: space(xl);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    background-color: color(neutral, 30);
    height: 380px;
    z-index: -1;
  }

  &__image {
    grid-column: 1 / span 6;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: space(l);
    background: color(neutral, 50);

    @include mq(s) {
      grid-column: 1 / span 4;
    }

    @include mq(m) {
      grid-column: 2 / span 5;
    }
  }

  nav {
    grid-column: 1 / span 6;
    padding-bottom: space(l);

    @include mq(m) {
      grid-column: 2 / span 7;
    }
  }

  &__headline {
    position: relative;
    color: color(primary);
    grid-column: 1 / span 5;

    @include mq(m) {
      grid-column: 2 / span 9;
    }
  }

  &__subline {
    color: color(neutral);
    margin-top: space(s);

    @include mq(m) {
      margin-top: space(m);
    }
  }

  &__placeholder {
    grid-column: 1 / span 6;
    height: 220px;

    @include mq(s) {
      grid-column: 1 / span 4;
    }

    @include mq(m) {
      grid-column: 2 / span 5;
    }
  }

  &__line {
    grid-column: 1 / span 6;
    height: 1px;
    background-position: top;
    background-size: 20px 1px;
    background-repeat: repeat-x;
    background-image: linear-gradient(
      to right,
      color(primary) 33%,
      rgba(255, 255, 255, 0) 0%
    );
    margin-bottom: space(m);

    @include mq(m) {
      grid-column: 1 / span 9;
    }
  }

  &__watchlist-button {
    margin-top: space(m);

    @include mq(m) {
      position: absolute;
      left: -75px;
      top: 12px;
      margin-top: 0;
    }

    @include mq(l) {
      top: 18px;
    }
  }

  &__intro {
    padding-left: space(s);
    position: relative;
    grid-column: 1 / span 6;
    margin: space(xl) 0 0;

    @include mq(s) {
      grid-column: 2 / span 4;
      margin-left: -13%;
      padding-left: 13%;
    }

    @include mq(m) {
      grid-column: 3 / span 8;
      padding-left: 0;
      margin-left: 0;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background: color(primary);

      @include mq(m) {
        left: calc((100% - 11 * 30px) / 12 * -2);
      }
    }

    p {
      margin-bottom: 0;
    }
  }
}
