.survey-loading-inline {
  align-items: center;
  display: flex;
  gap: space(s);

  svg {
    width: 1em;
    height: 1em;
  }
}
