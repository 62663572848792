.contacts {
  position: relative;

  &__headline {
    margin-bottom: space(m);
  }

  &__watchlist-button {
    position: absolute;
    top: space(m);
    right: -11px;
    z-index: 1;

    @include mq(m) {
      left: calc(-50px - 30px);
      right: auto;
    }
  }

  &__contact {
    position: relative;

    &-inner {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;

      @include mq(m) {
        flex-direction: row;
      }
    }

    a {
      display: block;
    }

    &-header {
      display: flex;
      flex-direction: column;
      width: 100%;

      @include mq(m) {
        flex-direction: row;
      }
    }

    &-image {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      flex-shrink: 0;
      display: grid;
      place-items: center;
      overflow: hidden;

      @include mq(m) {
        width: 80px;
        height: 80px;
        margin-right: space(m);
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &--members &-image {
      border-radius: 0;
    }

    &-name {
      margin-top: space(s);

      @include mq(m) {
        margin-top: 0;
      }
    }

    &-meta,
    &-location,
    &-links,
    &-social-links {
      width: 100%;
      margin-top: space(s);

      @include mq(m) {
        width: 50%;
      }
    }

    &-meta,
    &-links {
      @include mq(m) {
        width: 60%;
      }
    }

    &-location,
    &-social-links {
      @include mq(m) {
        width: 40%;
        padding-left: space(s);
      }
    }

    &-links {
      margin-top: space(m);
    }

    &-social-links {
      display: flex;
      flex-wrap: wrap;
      margin-top: space(m);

      > a {
        margin-right: 2px;
      }
    }

    &-optional-link + &-optional-link {
      width: 100%;
      margin-top: space(m);
    }

    & + & {
      padding-top: space(m);
    }

    & + & > .contacts__watchlist-button {
      margin-top: space(m);
    }

    & + & > &-inner {
      &:before {
        content: '';
        width: 30%;
        max-width: 185px;
        height: 1px;
        margin-bottom: space(m);
        background: color(neutral, 90);
      }
    }
  }

  &--has-grid {
    @include get-grid();

    .contacts__headline {
      grid-column: 1 / span 6;

      @include mq(s) {
        grid-column: 2 / span 4;
      }

      @include mq(m) {
        grid-column: 3 / span 8;
      }
    }

    .contacts__contact {
      grid-column: 1 / span 6;

      &:first-of-type {
        border-top: 1px solid color(neutral, 90);
        padding-top: space(m);
      }

      &:last-of-type {
        border-bottom: 1px solid color(neutral, 90);
        padding-bottom: space(m);
      }

      @include mq(s) {
        grid-column: 2 / span 4;
      }

      @include mq(m) {
        grid-column: 3 / span 8;
      }
    }
  }
}
