.search-hits {
  display: flow-root;

  &__hits {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__hit:nth-child(odd) {
    background: color(neutral, 30);
  }

  &__meta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: space(l) 0;
  }

  &__load-more {
    margin-top: space(xs);
    outline: none;
    border: none;
    appearance: none;
    background: none;
    text-transform: uppercase;
    color: color(primary);
    display: flex;
    align-items: center;

    svg {
      margin-right: space(xs);
    }

    &:focus {
      [data-whatintent='keyboard'] & {
        color: color(interaction);
      }
    }

    &[disabled] {
      color: color(neutral, 50);
      user-select: none;
      cursor: not-allowed;
    }
  }

  /**
   * Highlighting and Snippeting
   */
  .ais-Highlight-highlighted,
  .ais-Snippet-highlighted {
    background-color: color(primary, 70);
    color: color(neutral, 10);
    font-style: normal;
  }
}
