.autocomplete {
  &__form {
    border-radius: 50px;
    border: 1px solid color(primary);
    height: space(l);
    margin: space(l) space(s) space(m) space(s);
    position: relative;
    background: color(neutral, 10);
    z-index: 2;

    @include mq(l) {
      margin: space(l) space(l) space(m) space(l);
    }
  }

  &__input {
    @include typography(copy);

    appearance: none;
    background: none;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    border: none;
    color: color(neutral, 90);
    height: 100%;
    outline: none;
    width: 100%;
    padding-left: space(l);

    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  &__submit {
    position: absolute;
    left: 0;
    top: -1px;
    background: none;
    color: color(primary);
    width: space(l);
    height: space(l);
    outline: none;
    border: none;
    appearance: none;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;
    }
  }

  &__reset {
    position: absolute;
    top: 14px;
    right: 14px;
  }

  &__panel {
    position: absolute;
    top: space(xl);
    left: space(s);
    right: space(s);
    overflow: hidden;
    @include typography(copy);
    z-index: 1;

    @include mq(l) {
      left: space(l);
      right: space(l);
    }
  }

  &__layout {
    background-color: color(neutral, 10);
    border-radius: 25px;
    border: 1px solid color(primary);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding-top: space(m);
    padding-bottom: space(xs);
  }

  &__source {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
  }

  &__list {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-direction: column;
    align-items: flex-start !important;
    gap: space(s);
    padding: 0 space(m);
  }

  &__item {
    cursor: pointer;
  }

  /**
   * Highlighting
   */
  .ais-Highlight-highlighted {
    background-color: transparent;
    color: color(primary);
    font-style: normal;
  }
}
