/**
  * Default config for breakpoints and steps
  * - breakpoints: defines range in between which the steps should be calculated
  *   - has to have a minimum of two values (min & maximum)
  *   - can have more than two breakpoints
  * - steps: number of calcutated steps between two breakpoints
  *   'font-size', 'line-height' and 'letter-spacing'
**/
$as-globals: (
  breakpoints: 640 1280,
  steps: 4
);

/**
  * Default config for type class specification
  * - Specifys min and max values for css properties
  *   'font-size', 'line-height' and 'letter-spacing'
  * - Values will match breakpoints as defined in $as-globals
**/
$as-config: (
  fontSizes: 12 50,
  lineHeights: 1 1.4,
  letterSpacings: 0 0
);

/**
  * Definition of CSS properties
  **/
$as-properties: (
  fontSize: 'font-size',
  lineHeight: 'line-height',
  letterSpacing: 'letter-spacing'
);

@function interpolate(
  $width: number,
  $from: number,
  $to: number,
  $min: number,
  $max: number
) {
  @return $min + ($max - $min) * (($width - $from) / ($to - $from));
}

@function roundPX($number) {
  @if ($number) {
    @return #{round($number * 10000) / 10000}px;
  } @else {
    @return 0;
  }
}

/**
 * Automatically generates type css in linear steps between minimum & maximum breakpoin
 * @param {Object} $config - type class configs
 * @param {Object} $globals - breakpoints and steps
 * @param {Boolean} $$properties - css properties
 */

@mixin adaptive-size(
  $config: $as-config,
  $globals: $as-globals,
  $properties: $as-properties
) {
  // Global keys
  $breakpoints: map-get($globals, 'breakpoints');
  $steps: map-get($globals, 'steps');

  // Text type keys
  $fontSizes: map-get($config, 'fontSizes');
  $lineHeights: map-get($config, 'lineHeights');
  $letterSpacings: map-get($config, 'letterSpacings');

  // CSS Props
  $fontSizeProp: map-get($properties, 'fontSize');
  $lineHeightProp: map-get($properties, 'lineHeight');
  $letterSpacingProp: map-get($properties, 'letterSpacing');

  @for $i from 1 through length($fontSizes) {
    $breakpoint: nth($breakpoints, $i);
    $fontSize: nth($fontSizes, $i);
    $lineHeight: nth($lineHeights, $i);
    $letterSpacing: nth($letterSpacings, $i);

    @if $i == 1 {
      #{$fontSizeProp}: roundPX($fontSize);
      #{$lineHeightProp}: roundPX($lineHeight * $fontSize);
      #{$letterSpacingProp}: roundPX($letterSpacing);
    } @else {
      $next: nth($breakpoints, $i);
      $current: nth($breakpoints, $i - 1);

      // Check if step is number or list
      $step: $steps;

      @if type-of($step) == list {
        $step: nth($steps, $i - 1);
      }

      $partialStep: ($next - $current) / $step;

      // start after 1 step width

      @for $j from 1 through $step {
        $stepWidth: $current + ($partialStep * $j);
        $fontSizeStep: interpolate(
          $stepWidth,
          $current,
          $next,
          nth($fontSizes, $i - 1),
          $fontSize
        );
        $lineHeightStep: interpolate(
          $stepWidth,
          $current,
          $next,
          nth($lineHeights, $i - 1),
          $lineHeight
        );
        $letterSpacingStep: interpolate(
          $stepWidth,
          $current,
          $next,
          nth($letterSpacings, $i - 1),
          $letterSpacing
        );

        @media (min-width: roundPX($stepWidth)) {
          #{$fontSizeProp}: roundPX($fontSizeStep);
          #{$lineHeightProp}: roundPX($lineHeightStep * $fontSizeStep);
          #{$letterSpacingProp}: roundPX($letterSpacingStep);
        }
      }
    }
  }
}

@mixin adaptive-size-css-variables(
  $key,
  $config: $as-config,
  $globals: $as-globals
) {
  @include adaptive-size(
    $config,
    $globals,
    (
      fontSize: --#{$key}-font-size,
      lineHeight: --#{$key}-line-height,
      letterSpacing: --#{$key}-letter-spacing
    )
  );
}
