.survey-start-teaser {
  @import '~keen-slider/keen-slider';

  @include get-grid(
    (
      default: 6
    ),
    false
  );

  &__topline {
    grid-column: 1 / -1;
    margin-bottom: space(xs);

    @include mq(m) {
      grid-column: 2 / -2;
    }
  }

  &__slider {
    grid-column: 1 / -1;
    grid-row: 2;

    @include mq(m) {
      grid-column: 2 / -2;
    }
  }

  &__prev {
    @include mq($until: m) {
      position: absolute;
      bottom: -20px;
      right: calc(#{space(xs)} + 40px);
    }

    @include mq(m) {
      display: flex;
      align-items: center;
      justify-content: end;
      grid-column: 1 / span 1;
      grid-row: 2;
    }
  }

  &__next {
    @include mq($until: m) {
      position: absolute;
      bottom: -20px;
      right: 0;
    }

    @include mq(m) {
      display: flex;
      align-items: center;
      grid-column: 6 / span 1;
      grid-row: 2;
    }
  }
}
