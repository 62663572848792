.select {
  position: relative;
  margin-top: 30px;

  label {
    color: color(neutral);
    display: block;
    text-transform: uppercase;
    line-height: 1;
    position: absolute;
    top: 16px;
    left: space(m);
    transition: top 0.2s ease-in-out;

    @include mq(m) {
      top: 30px;
    }

    span > span {
      color: color(primary);
      padding-left: 4px;
    }
  }

  svg {
    pointer-events: none;
    position: absolute;
    right: space(m);
    top: 50%;
    transform: translateY(-50%);
    color: color(primary);
  }

  &__wrapper {
    position: relative;
  }

  select {
    appearance: none;
    border: 1px solid color(primary);
    border-radius: 40px;
    font-size: inherit;
    font-weight: normal;
    line-height: 1;
    outline: none;
    width: 100%;
    height: 52px;
    padding: space(xs) space(m);
    background: color(neutral, 10);
    color: color(neutral, 10);
    transition: color 0.2s ease-in-out;

    @include mq(m) {
      height: 80px;
      padding: space(s) space(m);
    }

    &:focus {
      border-color: color(interaction);

      & + svg {
        color: color(interaction);
      }
    }

    &[disabled] {
      cursor: not-allowed;
      border-color: color(neutral, 50);
    }

    option {
      color: color(neutral);
    }

    &::-ms-expand {
      display: none;
    }
  }

  &__error {
    color: color(warning);
    display: none;
    margin-top: space(s);
    margin-left: space(m);
  }

  &--error &__error,
  select.user-error ~ &__error {
    display: block;
  }

  &--error {
    label {
      color: color(warning);
    }

    select {
      border-color: color(warning);
    }
  }

  &--success label {
    color: color(success);
  }

  &--success {
    select {
      border-color: color(success);
    }
  }

  &--has-value {
    select {
      color: color(neutral);
    }

    label {
      top: -30px;

      @include mq(m) {
        top: -30px;
      }
    }
  }
}
