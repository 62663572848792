.credits {
  overflow: hidden;

  &__element {
    position: absolute;
    bottom: -19px;
    right: -20px;
    border: 1px solid color(neutral, 90);
    border-radius: 50px;
    background: color(neutral, 90);
    color: color(neutral, 10);
    min-height: 26px;
    padding: 2px 26px 18px 10px;

    display: inline-flex;
    align-items: center;

    p {
      margin-left: space(xs);
    }
  }
}
