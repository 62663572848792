.current-filters {
  display: flex;
  flex-direction: column;

  @include mq(s) {
    flex-direction: row;
    align-items: center;
  }

  > p {
    flex-shrink: 0;
    align-self: flex-start;
    margin: space(xs) space(s) space(xs) 0;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;

    @include mq(s) {
      align-items: center;
    }

    > * {
      margin: space(xs) space(s) space(xs) 0;
    }
  }
}
