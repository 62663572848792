.survey-container {
  @include get-grid();

  background: color(neutral, 30);
  padding-bottom: space(l);
  padding-top: space(l);
  position: relative;

  &__prev-button {
    @include mq($until: m) {
      position: absolute;
      bottom: -20px;
      right: calc(30px + #{space(xs)} + 40px);
    }

    @include mq(m) {
      padding-top: calc(48px + 32px);
      display: flex;
      align-items: start;
      justify-content: end;
      grid-column: 1 / span 2;
    }
  }

  &__next-button {
    @include mq($until: m) {
      position: absolute;
      bottom: -20px;
      right: 30px;
    }

    @include mq(m) {
      padding-top: calc(48px + 32px);
      display: flex;
      align-items: start;
      justify-content: start;
      grid-column: 11 / span 2;
    }
  }

  &__inner {
    grid-column: 1 / span 6;

    @include mq(m) {
      grid-column: 3 / span 8;
      grid-row: 1;
    }
  }

  &__content {
    display: flex;
    overflow: hidden;
  }

  &__headline {
    margin-bottom: space(m);
  }
}
