.member-dashboard {
  position: relative;

  main {
    @include grid-container();
  }

  &__content {
    margin: 0 auto;
    max-width: 1380px;
    width: 100%;
  }

  &__content .member-dashboard__block--contacts {
    .contacts__headline {
      @include typography(headline-3-sans);
    }

    margin-bottom: space(l);

    + .member-dashboard__block--link-list {
      margin-top: space(l);
    }
  }
}
