[data-js-module='floating-layer'] {
  [data-js-element='floating-layer__toggle'] * {
    pointer-events: none;
  }

  [data-js-element='floating-layer__layer'] {
    position: absolute;
    top: -30px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: color(neutral, 10);
    box-shadow: 0 2px 20px 0 rgba(135, 135, 135, 0.3);
    border-radius: 40px;
    padding: space(l);
    z-index: get-z-index(floating-layer);

    > * + * {
      margin-top: space(s);
    }
  }
}
