.autocomplete-results {
  &__form {
    display: grid;
    grid-template-columns: 1fr auto;
  }

  &__input-outer {
    position: relative;
  }

  &__input-wrapper {
    --br: 50px;

    position: relative;
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr auto;
    border-radius: var(--br);
    border: 1px solid color(primary);
    height: 52px;
    background-color: color(neutral, 10);
    z-index: 5;

    @include mq(s) {
      border-radius: var(--br) 0 0 var(--br);
      border-right: 0;
    }

    @include mq(m) {
      height: space(xl);
    }
  }

  &__input {
    @include typography(copy);

    appearance: none;
    background: none;
    border: none;
    color: color(neutral, 90);
    height: 100%;
    outline: none;
    width: 100%;

    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  .icon--search {
    color: color(primary);
    margin: 0 space(s) 0 space(m);
  }

  &__reset {
    margin: 0 14px;
  }

  &__submit {
    position: relative;

    @include mq($until: s) {
      @include hide-visually;
    }
  }

  &__submit::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: space(xl);
    border-top: 1px solid color(primary);
    border-bottom: 1px solid color(primary);
  }

  &__panel {
    position: absolute;
    top: 26px;
    left: 0;
    right: 0;
    overflow: hidden;
    @include typography(copy);
    z-index: 4;

    @include mq(m) {
      top: 40px;
    }
  }

  &__layout {
    background-color: color(neutral, 10);
    border-radius: 25px;
    border: 1px solid color(primary);
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding-top: space(l);
    padding-bottom: space(xs);
  }

  &__source {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
  }

  &__list {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-direction: column;
    align-items: flex-start !important;
    gap: space(s);
    padding: 0 space(m);
  }

  &__item {
    cursor: pointer;
  }

  /**
   * Highlighting
   */
  .ais-Highlight-highlighted {
    background-color: transparent;
    color: color(primary);
    font-style: normal;
  }
}
