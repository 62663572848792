@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes imageAnimation {
  0% {
    transform: scale(0.7) translateY(200px);
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes contentAnimation {
  0% {
    transform: scale(0.7) translateY(-200px);
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes headlineAnimation {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes circleAnimation {
  0% {
    border-width: 150px;
    transform: scale(0);
    opacity: 1;
  }

  40% {
    border-width: 150px;
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    border-width: 20px;
    opacity: 0;
    transform: scale(1);
  }
}

.teaser-article {
  grid-column: span 6;
  align-self: start;

  @include mq(s) {
    grid-column: span 3;
  }

  @include mq(m) {
    grid-column: span 5;
  }

  @include get-grid(
    (
      default: 6,
      s: 3,
      m: 5
    ),
    false
  );

  &__grid {
    position: relative;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    grid-column: span 6;

    @include mq(s) {
      grid-column: span 3;
    }

    @include mq(m) {
      grid-column: 2 / span 4;
    }
  }

  &__tags {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    opacity: 0;

    @include get-grid(
      (
        default: 6,
        s: 3,
        m: 4
      ),
      false
    );

    &-list {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  &__tag {
    margin-bottom: space(xs);
    pointer-events: auto;
  }

  &__link {
    [data-whatintent='mouse'] &,
    [data-whatintent='touch'] & {
      outline: 0;
    }

    @include get-grid(
      (
        default: 6,
        s: 3,
        m: 4
      ),
      false
    );
  }

  &__content,
  &__image,
  &__tags-placeholder {
    grid-column: 3 / span 4;
    width: calc(100% + 30px);
    margin-left: -30px;

    @include mq(s) {
      grid-column: 2 / span 2;
    }

    @include mq(m) {
      margin-left: 0px;
      grid-column: 2 / span 3;
    }
  }

  &__image,
  &__tags-placeholder {
    opacity: 0;

    @include mq(l) {
      width: 100%;
      padding-right: space(m);
    }
  }

  &__content {
    opacity: 0;
    margin-top: space(m);

    & > * + * {
      margin-top: space(m);
    }

    svg {
      color: color(primary);
      width: 73px;
      height: 20px;
    }
  }

  &__headline {
    opacity: 0;
    grid-column: 1 / span 6;
    margin-top: space(m);

    @include mq(s) {
      grid-column: 1 / span 3;
    }

    @include mq(m) {
      grid-column: 1 / span 4;
      padding-right: space(m);
    }
  }

  &__watchlist-button {
    position: absolute;
    left: -10px;
    opacity: 0;
  }

  &__circle {
    position: absolute;
    top: calc(50% - 150px);
    left: calc(50% - 150px);
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 150px solid color(primary);
    pointer-events: none;
    transform: scale(0);
  }

  &[data-animate='true'] {
    .teaser-article__tags,
    .teaser-article__watchlist-button {
      animation: opacityAnimation 0.2s 0.7s ease-in-out forwards;
    }

    .teaser-article__image {
      animation: imageAnimation 0.3s 0.5s ease-in-out forwards;
    }

    .teaser-article__content {
      animation: contentAnimation 0.3s 0.5s ease-in-out forwards;
    }

    .teaser-article__headline {
      animation: headlineAnimation 0.3s 0.5s ease-in-out forwards;
    }

    .teaser-article__circle {
      animation: circleAnimation 0.5s 0.1s ease-in-out forwards;
    }
  }

  &__link {
    h3 span {
      display: block;
      transition: transform 0.3s ease-in-out;
    }

    &:hover,
    &:focus,
    &:active {
      h3 span {
        transform: scale(1.01);
      }
    }

    &:hover h3 span {
      color: color(primary, 70);
    }

    &:active h3 span {
      color: color(neutral, 90);
    }

    &:focus-visible h3 span {
      color: color(interaction);
      outline: 0;
    }
  }
}
