.link-list {
  @include get-grid();

  &__wrapper {
    grid-column: 1 / span 6;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 3 / span 8;
    }
  }

  h3 {
    margin: 0 0 space(xs);
  }

  &__links {
    display: flex;
    flex-direction: column;

    > a + a {
      margin-top: space(s);
    }
  }

  &--tags {
    .link-list__wrapper {
      @include mq(m) {
        display: flex;
        align-items: flex-start;
      }

      h3 {
        margin-right: space(s);
        flex-shrink: 0;
      }
    }

    .link-list__links {
      flex-direction: row;
      flex-wrap: wrap;

      a {
        margin-top: 0;
        margin-bottom: space(xs);
        margin-right: space(xs);
      }
    }
  }
}
