.newsletter-signup {
  position: relative;
  padding: space(l) 0;
  background: color(neutral, 40);

  @include get-grid();

  align-items: start;

  &__intro,
  &__form {
    grid-column: 1 / span 6;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 3 / span 7;
    }
  }

  &__intro {
    p {
      margin-top: space(xs);

      @include mq(m) {
        margin-top: space(m);
      }
    }
  }

  &__form {
    margin: space(m) 0;

    @include mq(m) {
      grid-column: 3 / span 8;
    }

    button {
      min-width: 200px;
    }
  }
}
