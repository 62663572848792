.textarea-richtext {
  position: relative;
  margin-top: 30px;

  label {
    position: absolute;
    color: color(neutral);
    display: block;
    text-transform: uppercase;
    top: 78px;
    left: space(m);
    line-height: 1;
    transition: top 0.2s ease-in-out;

    span > span {
      color: color(primary);
      padding-left: 4px;
    }
  }

  textarea {
    @include hide-visually;
  }

  &__editor {
    height: 250px;
  }

  .ql-container {
    font-family: inherit;
  }

  .ql-container.ql-snow,
  .ql-toolbar.ql-snow {
    border-color: color(primary);
    border-radius: 40px;
  }

  .ql-container.ql-snow {
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    p,
    ul,
    ol {
      @include typography(copy);
    }

    strong,
    b {
      font-weight: 500;
    }

    a {
      position: relative;
      text-decoration: underline;
      text-decoration-thickness: from-font;
      color: inherit !important;
    }
  }

  .ql-toolbar.ql-snow {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: space(s);

    button .ql-stroke {
      stroke: color(primary);
    }

    button .ql-fill {
      fill: color(primary);
    }

    button.ql-active,
    button:focus,
    button:hover {
      .ql-stroke {
        stroke: color(primary, 90);
      }

      .ql-fill {
        fill: color(primary, 90);
      }
    }
  }

  &--has-focus {
    .ql-container.ql-snow,
    .ql-toolbar.ql-snow {
      border-color: color(interaction);
    }

    label {
      top: -30px;
    }
  }

  textarea:not(:placeholder-shown) + label,
  textarea[placeholder=''] + label {
    top: -30px;
  }

  &__error {
    color: color(primary);
    display: none;
    margin-top: space('s');
    margin-left: space(m);
  }

  &--error &__error,
  textarea.user-error ~ &__error {
    display: block;
  }

  &--error label {
    color: color(warning);
  }

  &--error {
    .ql-container.ql-snow,
    .ql-toolbar.ql-snow {
      border-color: color(warning);
    }
  }

  &--success label {
    color: color(success);
  }

  &--success {
    .ql-container.ql-snow,
    .ql-toolbar.ql-snow {
      border-color: color(success);
    }
  }
}
