.teaser-contacts {
  grid-column: span 6;
  align-self: start;
  transition: 0.3s ease-in-out;

  @include mq(s) {
    grid-column: span 3;
  }

  @include mq(m) {
    grid-column: span 5;
  }

  @include get-grid(
    (
      default: 6,
      s: 3,
      m: 5
    ),
    false
  );

  &__grid {
    grid-column: span 6;
    position: relative;

    @include mq(s) {
      grid-column: span 3;
    }

    @include mq(m) {
      grid-column: 2 / span 4;
    }
  }

  &__link {
    [data-whatintent='mouse'] &,
    [data-whatintent='touch'] & {
      outline: 0;
    }

    @include get-grid(
      (
        default: 6,
        s: 3,
        m: 4
      ),
      false
    );
  }

  &__content,
  &__image {
    grid-column: 3 / span 4;
    margin-left: -30px;

    @include mq(s) {
      grid-column: 2 / span 2;
      margin-left: 0px;
    }

    @include mq(m) {
      grid-column: 2 / span 3;
    }
  }

  &__headline {
    grid-column: 1 / span 6;
    margin-top: space(m);

    @include mq(s) {
      grid-column: 1 / span 3;
    }

    @include mq(m) {
      grid-column: 1 / span 4;
    }
  }

  &__content {
    margin-top: space(m);

    & * + * {
      margin-top: space(m);
    }

    svg {
      color: color(primary);
      width: 73px;
      height: 20px;
    }
  }

  &__image {
    width: 142px;
    height: 142px;

    img {
      border-radius: 50%;
    }
  }

  &--has-no-image &__grid:before {
    @include mq(s) {
      content: '';
      width: 142px;
      height: 142px;
      display: block;
    }
  }

  &__watchlist-button {
    position: absolute;
    left: -10px;
  }

  &[data-animate='false'] {
    opacity: 0;
    transform: translateY(50px);
  }

  &[data-animate='true'] {
    opacity: 1;
    transform: translateY(0);
  }
}
