@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: space(m);
    height: space(m);
    animation: spin 1s infinite linear;
    transform-origin: center;
  }
}
