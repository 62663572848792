.checkbox {
  display: block;
  width: 100%;

  &__wrapper {
    display: flex;
  }

  &__label {
    align-items: center;
    color: color('neutral');
    cursor: pointer;
    display: flex;
    hyphens: auto;
    padding-left: space(s);
    position: relative;
  }

  &__custom-checkbox {
    align-items: center;
    border: 1px solid color(primary);
    cursor: pointer;
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    position: relative;
    height: 30px;
    width: 30px;
    border-radius: space(xs);
    color: color(primary);

    @include mq(m) {
      height: 40px;
      width: 40px;
    }

    svg {
      display: none;
      fill: currentColor;
      margin-left: 2px;
    }
  }

  &__error {
    color: color(warning);
    display: none;
    margin-top: space('s');
  }

  &--success .checkbox__custom-checkbox {
    border-color: color(success);
    color: color(success);
  }

  &--error &__error,
  input.user-error ~ &__error {
    display: block;
  }

  &--error .checkbox__custom-checkbox,
  input.user-error ~ &__custom-checkbox {
    border-color: color(warning);
    color: color(warning);
  }

  input {
    @include hide-visually();

    &[disabled] ~ .checkbox__label,
    &[disabled] ~ .checkbox__custom-checkbox {
      cursor: not-allowed;
      color: color(neutral);
      opacity: 0.5;
    }

    &:checked ~ .checkbox__custom-checkbox svg {
      display: block;
    }

    &:focus ~ .checkbox__custom-checkbox {
      color: color(interaction);
      border-color: color(interaction);
    }
  }
}
