.video {
  position: relative;
  z-index: get-z-index('video');

  @include get-grid();

  &__wrapper {
    position: relative;
    grid-column: 1 / span 6;

    @include mq(m) {
      grid-column: 2 / span 9;
    }
  }

  &__caption {
    grid-column: 2 / span 5;
    margin-top: space(s);

    @include mq(m) {
      grid-column: 4 / span 6;
    }
  }

  @media print {
    display: none;
  }

  &__poster {
    left: 0;
    position: absolute;
    top: 0;

    &-image {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &__embed_container,
  &__poster,
  &__button {
    padding-top: 56.26%;
    width: 100%;
  }

  &__iframe,
  &__player {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__embed_container {
    position: relative;
    z-index: get-z-index('embed-container', 'video');
  }

  &__button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    left: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    top: 0;
    z-index: get-z-index('button', 'video');

    [data-whatintent='mouse'] &,
    [data-whatintent='touch'] & {
      outline: 0;
    }

    &-icon {
      height: 53px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: transform 250ms ease-out;
      width: 53px;

      @include mq(s) {
        width: 83px;
        height: 83px;
      }

      &-circle {
        fill: color(neutral, 10);
        stroke: color(primary);
        transition: all 250ms ease-out;
      }

      &-arrow {
        fill: color(primary);
        transition: all 250ms ease-out;
      }
    }
  }

  &--is-playing &__button,
  &--is-playing &__poster {
    display: none;
  }

  &__button:hover &__button-icon-circle {
    stroke: color(primary, 70);
  }

  &__button:active &__button-icon-arrow,
  &__button:active &__button-icon-circle {
    stroke: color(primary, 90);
  }

  &__button:hover &__button-icon-arrow {
    fill: color(primary, 70);
  }

  &__button:focus &__button-icon-circle {
    stroke: color(interaction);
  }
}
