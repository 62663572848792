.teaser-highlight {
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  grid-column: span 6;
  transition: 0.4s ease-in-out;

  @include mq(s) {
    grid-column: span 6;
  }

  @include mq(m) {
    grid-column-start: 2;
    grid-column-end: span 11;
  }

  &__content {
    grid-column: 2 / span 5;
    margin-top: space(m);

    & > * + * {
      margin-top: space(m);
    }

    svg {
      color: color(primary);
      width: 73px;
      height: 20px;
    }

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 2 / span 5;
    }
  }

  &__tags {
    position: absolute;
    top: 57px;
    left: 0;
    pointer-events: none;
    width: 100%;

    @include mq(m) {
      width: auto;
      top: space(s);
      left: 32%;
    }

    &[style*='--ratio'] {
      @include mq(m) {
        height: auto;
        padding-bottom: 0;
      }

      > * {
        @include mq(m) {
          position: relative;
        }
      }
    }

    &-inner {
      display: flex;
      align-items: flex-end;
    }
  }

  &__tag {
    margin-bottom: space(xs);
    pointer-events: auto;
  }

  &__link {
    [data-whatintent='mouse'] &,
    [data-whatintent='touch'] & {
      outline: 0;
    }

    @include get-grid(
      (
        default: 6,
        s: 6,
        m: 11
      ),
      false
    );
  }

  &__image {
    grid-column: 1 / span 6;
    height: auto;
    width: calc(100% + 60px);
    margin: 0 -30px;
    background: color(neutral, 30);

    @include mq(s) {
      grid-column: 1 / span 6;
    }

    @include mq(m) {
      grid-column: 5 / span 7;
      width: 100%;
      margin: 0;
      grid-row: 1;
    }
  }

  &__headline {
    grid-column: 1 / span 6;
    margin-top: space(m);
    padding-top: space(s);

    @include mq(s) {
      grid-column: 1 / span 5;
    }

    @include mq(m) {
      grid-column: 1 / span 4;
      padding-top: 0;
      grid-row: 1;
      align-self: end;
    }
  }

  &__watchlist-button {
    position: absolute;
    left: -10px;
  }

  &[data-animate='false'] {
    opacity: 0;
    transform: translateY(80px);
  }

  &[data-animate='true'] {
    opacity: 1;
    transform: translateY(0);
  }
}
