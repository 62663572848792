.sub-navigation {
  position: relative;

  &__headline {
    margin-bottom: space(s);
    display: none;

    @include mq(m) {
      display: block;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: space(s) 0;
    display: flex;
    overflow-y: auto;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 75px;
      height: 100%;
      white-space: nowrap;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 100%
      );

      @include mq(m) {
        display: none;
      }
    }

    @include mq(m) {
      flex-direction: column;
      padding: 0;
    }
  }

  &__item {
    flex-shrink: 0;
  }

  &__item + &__item {
    margin-left: space(s);

    @include mq(m) {
      margin-left: 0;
      margin-top: space(s);
    }
  }

  &__divider {
    @include mq(m) {
      display: none;
    }
  }
}
