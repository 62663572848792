.article-page {
  position: relative;

  main {
    @include grid-container();
  }

  &__content {
    margin: 0 auto;
    max-width: 1380px;
    width: 100%;
  }
}
