.highlight-list {
  position: relative;
  padding: space(l) 0;
  background: color(neutral, 40);

  @include mq(m) {
    padding: space(xl) 0;
  }

  @include get-grid();

  align-items: start;

  &__intro {
    grid-column: 1 / span 6;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 2 / span 4;
    }

    p {
      margin-top: space(xs);

      @include mq(m) {
        margin-top: space(m);
      }
    }
  }

  &__image {
    grid-column: 1 / span 6;
    margin-top: space(m);

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 1 / span 5;
      margin-top: space(l);
    }

    @include get-grid(
      (
        default: 6,
        s: 4,
        m: 5
      ),
      false
    );
  }

  &__image.content-image--landscape {
    > div {
      grid-column: 1 / span 6;

      @include mq(s) {
        grid-column: 1 / span 4;
      }

      @include mq(m) {
        grid-column: 1 / span 5;
      }
    }

    figcaption {
      grid-column: 2 / span 5;

      @include mq(s) {
        grid-column: 2 / span 3;
      }

      @include mq(m) {
        grid-column: 3 / span 3;
      }
    }
  }

  &__image.content-image--portrait {
    > div {
      grid-column: 1 / span 6;

      @include mq(s) {
        grid-column: 1 / span 3;
      }

      @include mq(m) {
        grid-column: 2 / span 4;
      }
    }

    figcaption {
      grid-column: 2 / span 5;

      @include mq(s) {
        grid-column: 1 / span 4;
      }

      @include mq(m) {
        grid-column: 3 / span 3;
      }
    }
  }

  &__list {
    grid-column: 1 / span 6;
    margin-top: space(l);

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 7 / span 5;
      grid-row: 1 / span 3;
      margin-top: 0;
    }
  }

  &__item {
    display: flex;

    &-icon {
      position: relative;
      flex-shrink: 0;
      margin-right: space(s);
      color: color(primary);

      @include mq(m) {
        margin-right: space(m);
      }

      span {
        position: absolute;
        top: -4px;
        right: 0;
        font-size: 14px;
        line-height: 1;
        font-weight: 700;
        font-family: $font-family-slab;

        .highlight-list--unordered & {
          top: -10px;
          right: -10px;
        }
      }
    }

    p {
      margin-top: space(s);
    }

    & + & {
      margin-top: space(l);
    }
  }

  &__cta {
    margin-top: space(l);
    margin-left: 56px;

    @include mq(m) {
      margin-left: 72px;
    }
  }
}
