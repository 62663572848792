.tag {
  display: inline-flex;
  flex-wrap: nowrap;
  text-transform: uppercase;
  color: color(neutral, 10);
  background: color(neutral, 90);
  border-radius: 13px;
  border: 1px solid color(neutral, 90);
  min-height: 26px;
  text-align: left;
  align-items: center;

  .icon {
    align-self: start;
    fill: currentColor;
    flex-shrink: 0;
  }

  span {
    padding: 2px space(s);
  }

  &[href],
  &[type='button'] {
    &:hover {
      color: color(neutral, 10);
      background: color(primary, 70);
      border-color: color(primary, 70);
    }

    &:active {
      color: color(neutral, 10);
      background: color(primary, 90);
      border-color: color(primary, 90);
    }

    &:focus-visible {
      color: color(neutral, 10);
      background: color(interaction);
      border-color: color(interaction);
      outline: 0;
    }

    &[disabled] {
      color: color(neutral, 10);
      background: color(neutral, 50);
    }
  }

  &[disabled] {
    color: color(neutral, 10);
    background: color(neutral, 50);
    border-color: color(neutral, 50);
  }

  &--small {
    min-height: 20px;

    span {
      padding: 0 12px;
      margin-top: -1px;
      line-height: 20px;
      font-weight: 700;
    }
  }

  &--border {
    background: none;
    color: color(neutral, 90);
  }
}
