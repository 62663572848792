.link {
  align-items: center;
  color: color(primary);
  display: inline-flex;
  text-transform: uppercase;

  svg {
    margin-right: space(xs);
  }

  &--no-transform {
    text-transform: none;
  }

  &--secondary {
    color: color(neutral, 10);
  }

  &:hover,
  .has-hoverable-children:hover & {
    color: color(primary, 70);
  }

  &:active,
  .has-hoverable-children:active & {
    color: color(neutral, 90);
  }

  &:focus-visible,
  .has-hoverable-children:focus-visible & {
    color: color(interaction);
    outline: 0;
  }

  &[disabled] {
    color: color(neutral, 50);
  }

  &--icon-right {
    flex-direction: row-reverse;
  }

  &__icon-wrapper {
    font-size: 0;

    > svg {
      display: block;
    }
  }

  &__arrow-icon {
    width: 73px;
    height: 20px;
    margin-left: space(xs);
    margin-right: 0;
  }

  &__external-icon {
    width: 24px;
    height: 24px;
  }

  &--tertiary {
    color: color(neutral, 90);

    &:active,
    .has-hoverable-children:active & {
      color: color(primary, 90);
    }
  }
}
