.light-switch {
  position: relative;

  label {
    display: inline-flex;
    align-items: center;
  }

  &__switch {
    position: relative;
    cursor: pointer;
    width: 60px;
    height: 36px;
    border: 1px solid color(neutral, 50);
    border-radius: 34px;
    background-color: color(neutral, 50);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  &__switch:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    border-radius: 50%;
    background-color: color(neutral, 10);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  &__label {
    align-items: center;
    color: color('neutral');
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    hyphens: auto;
    padding-left: space(m);
    position: relative;
  }

  &--error &__error {
    color: color(warning);
    display: none;
    margin-top: space('s');
  }

  &--error &__error,
  input.user-error ~ &__error {
    display: block;
  }

  &--success &__switch {
    border-color: color(success);
    color: color(success);
  }

  &--error &__switch,
  input.user-error ~ &__switch {
    border-color: color(warning);
    color: color(warning);
  }

  input {
    @include hide-visually;
  }

  input[disabled] ~ .light-switch__label,
  input[disabled] ~ .light-switch__switch {
    cursor: not-allowed;
    color: color(neutral);
    opacity: 0.5;
  }

  input:checked + &__switch {
    border-color: color(primary);
    background-color: color(primary);
  }

  input:focus + &__switch {
    [data-whatintent='keyboard'] & {
      border-color: color(interaction);
    }
  }

  input:checked + &__switch:before {
    transform: translateX(24px);
  }
}
