@keyframes watchlistButtonAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.watchlist-button {
  color: color(primary);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 62px;

  svg {
    width: 40px;
    height: 40px;
  }

  span {
    margin-top: space(xs);
    text-transform: uppercase;
  }

  &.added svg {
    animation: 0.4s watchlistButtonAnimation linear forwards;
  }

  &:hover {
    color: color(primary, 70);
  }

  &:focus {
    [data-whatintent='keyboard'] & {
      color: color(interaction);
    }
  }
}
