@mixin spacings-to-variables($map: $spacings) {
  @each $key in map-keys($map) {
    $space: map-get($map, $key);

    @if $space {
      @each $breakpoint in map-keys($space) {
        @if $breakpoint == 'default' {
          $defaultSpace: map-get($space, 'default');
          --spacings-#{$key}: #{$defaultSpace};
        } @else {
          $breakpointSpace: map-get($space, $breakpoint);
          @include mq($breakpoint) {
            --spacings-#{$key}: #{$breakpointSpace};
          }
        }
      }
    } @else {
      @error "ERROR: #{$key} does not exist in #{$map}";
    }
  }
}

@function derive-rgb-values-from-hex($hex) {
  @return red($hex), green($hex), blue($hex);
}

@mixin colors-to-variables($map: $colors) {
  @each $key in map-keys($map) {
    $colorMap: map-get($map, $key);

    @each $step in map-keys($colorMap) {
      @if $step == 'default' {
        $defaultColor: map-get($colorMap, 'default');
        $colorHex: map-get($colorMap, $defaultColor);

        // return default color
        --colors-#{$key}: #{$colorHex};

        // OPTIONAL: get colors with alpha chanhel certain colors (e.g. "neutral" and "primary")
        // @if $key == 'neutral' or $key == 'primary' {
        //   $aphaValues: (10, 20, 30, 40, 50, 60, 70, 80, 90);
        //   $colorRGB: derive-rgb-values-from-hex($colorHex);

        //   @each $alphaValue in $aphaValues {
        //     --colors-#{$key}-alpha#{$alphaValue}: rgba(
        //       #{$colorRGB},
        //       #{$alphaValue / 100}
        //     );
        //   }
        // }
      } @else {
        // return step color
        --colors-#{$key}-#{$step}: #{map-get($colorMap, $step)};
      }
    }
  }
}
