.survey-cover {
  &__topline {
    margin-bottom: space(xs);
  }

  &__headline {
    margin-bottom: space(s);
  }

  &__button {
    align-items: center;
    color: color(primary);
    display: flex;
    gap: space(xs);
    margin-bottom: space(xs);
    text-transform: uppercase;
  }
}
