.sticky-cta {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  transform: translateY(80px);
  visibility: hidden;
  transition: all 300ms ease;
  z-index: get-z-index('floating-layer');

  @include get-grid((), false);

  @include mq(m) {
    bottom: space(m);
    transform: translateY(80px + 32px);
  }

  &--visible {
    transform: translateY(0);
    visibility: visible;
    transition: transform 300ms ease;

    @include mq(m) {
      transform: translateY(0);
      visibility: visible;
    }
  }

  &__container {
    position: relative;
    height: 80px;
    grid-column: 1 / span 6;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 2px 20px 0 rgba(135, 135, 135, 0.25);
    background: color(neutral, 10);
    padding: space(m) 30px;
    transform-style: preserve-3d;
    transform-origin: 100% 40px;
    transition: transform 400ms ease;

    @include mq(m) {
      grid-column: 2 / span 10;
      padding: space(m) space(l);
      border-radius: 40px;
      transform: none;
    }

    &--toggled {
      transform: rotateX(-180deg);
    }
  }

  &__link {
    position: absolute;
    top: 50%;
    right: 30px;
    left: 30px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    backface-visibility: hidden;

    @include mq(m) {
      position: relative;
      right: auto;
      left: auto;
    }

    &:nth-child(2) {
      transform: translateY(-50%) rotateX(180deg);

      @include mq(m) {
        transform: translateY(-50%);
        margin-left: space(m);
      }
    }

    &:only-child {
      margin: 0 auto;
    }
  }

  &__toggle {
    @include button-reset();

    margin-right: space(s);
    display: flex;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      width: 9px;
      height: 9px;
      border: 1px solid color(primary);
      border-radius: 50%;
    }

    &:after {
      margin-left: space(xs);
    }

    &--1:before,
    &--2:after {
      background: color(primary);
    }

    @include mq(m) {
      display: none;
    }
  }
}
