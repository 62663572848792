.freeform-label.freeform-required::after {
  content: "*";
  margin-left: 5px;
  color: color(primary);
}

.checkboxes__label {
  display: block;
  margin-bottom: var(--spacings-m);
  text-transform: uppercase;
}

.checkbox--error .checkboxes__label {
  color: color(warning);
}

.checkbox__item {
  align-items: center;
  line-height: 1;
  margin-bottom: space(s);
  position: relative;
}

.freeform-input__error {
  color: color(warning);
  margin-top: space(s);
  margin-left: space(m);

  > p {
    grid-column: 1/span 4;
  }
}

.freeform-input__success {
  margin-top: space(s);
  margin-left: space(m);

  > p {
    grid-column: 1/span 4;
  }
}

.freeform-button-container {
  margin-top: space(m);
}
