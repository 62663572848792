.tabs {
  &__list {
    border-bottom: 1px solid color(primary);
    border-radius: 0;
    display: flex;
    flex-wrap: nowrap;
    list-style-type: none;
    margin: 0;
    overflow-x: scroll;
    padding: get-space('xs') 0 0;
    position: relative;
    white-space: nowrap;

    li {
      flex-shrink: 0;
    }

    li:last-of-type a {
      margin-right: 0;
    }
  }

  &__tab {
    align-items: center;
    appearance: none;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    color: color(primary);
    display: flex;
    padding: get-space('xs') get-space('m');
    position: relative;

    &[aria-hidden='true'] {
      display: none;
    }

    &:hover {
      border-color: color(neutral);
      color: color(primary);
    }

    &[aria-selected='true'],
    &:focus {
      border-color: color(primary);
      outline: none;
    }
  }

  &--centered &__list {
    justify-content: center;
  }
}
