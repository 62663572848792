.teaser-container {
  @include get-grid();

  &:has(div.teaser-container__no-results) {
    display: none;
  }

  &__headline {
    grid-column: 1 / span 6;
    margin-bottom: space(l);

    @include mq(m) {
      grid-column: 2 / span 10;
    }
  }

  &__teasers {
    position: relative;
    grid-column: 1 / span 6;
    grid-row-gap: space(xl);
    min-height: 400px;

    @include mq(m) {
      grid-column: 1 / span 12;
      grid-row-gap: space(xxl);
    }

    @include get-grid((), false);

    .teaser-recent + .teaser-recent {
      margin-top: calc(#{space(xl)} * -1);

      @include mq(m) {
        margin-top: calc(#{space(xxl)} * -1);
      }
    }
  }

  &__loading,
  &__no-results,
  &__error {
    grid-column: 1 / span 6;
    text-align: center;
    align-self: center;

    @include mq(m) {
      grid-column: 1 / span 12;
    }
  }

  &__meta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: space(l);
    grid-column: 1 / span 6;

    @include mq(m) {
      grid-column: 1 / span 12;
    }
  }

  &__load-more {
    margin-top: space(xs);
    outline: none;
    border: none;
    appearance: none;
    background: none;
    text-transform: uppercase;
    color: color(primary);
    display: flex;
    align-items: center;

    svg {
      margin-right: space(xs);
    }

    &:focus {
      [data-whatintent='keyboard'] & {
        color: color(interaction);
      }
    }
  }

  &--with-container {
    margin: space(l) auto;
    max-width: 1380px;
    width: 100%;

    @include mq(m) {
      margin: space(xl) auto;
    }
  }

  &--has-centered-headline &__headline {
    text-align: center;
  }

  &--has-background,
  &--has-background-no-padding {
    .teaser-container__teasers {
      padding: space(l) 0;

      &:before {
        content: '';
        position: absolute;
        width: calc(100% + 60px);
        height: 100%;
        margin-left: -30px;
        background: color(neutral, 30);

        @include mq(m) {
          width: 100%;
          margin-left: 0;
        }
      }
    }

    .teaser-recent {
      @include mq(m) {
        margin: 0 30px;
      }
    }
  }

  &--has-background-no-padding {
    .teaser-container__teasers {
      padding: 0;
    }
  }
}
