.downloads {
  width: 100%;

  &__headline {
    margin-bottom: space(m);
  }

  &__item {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include mq(m) {
      flex-direction: row;
      align-items: center;
    }

    a {
      flex-shrink: 0;
      margin-top: space(s);

      @include mq(m) {
        margin-left: space(s);
        margin-top: 0;
      }
    }
  }

  &__item + &__item {
    margin-top: space(s);
  }

  &--has-grid {
    @include get-grid();

    .downloads__headline,
    .downloads__list {
      grid-column: 1 / span 6;

      @include mq(s) {
        grid-column: 2 / span 4;
      }

      @include mq(m) {
        grid-column: 3 / span 7;
      }
    }

    .downloads__list {
      border-top: 1px solid color(neutral, 90);
      border-bottom: 1px solid color(neutral, 90);
      padding: space(m) 0;
    }
  }
}
