.teaser-focus-filter {
  position: relative;
  margin-bottom: space(xl);
  z-index: get-z-index('focus-filter');

  @include mq(m) {
    margin-bottom: space(xl);
  }

  @include get-grid();

  &__container {
    position: relative;
    grid-column: 1 / span 6;
    background: color(neutral, 10);
    box-shadow: 0 2px 20px 0 rgba(135, 135, 135, 0.3);
    border-radius: 40px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq(s) {
      display: block;
    }

    @include mq(m) {
      grid-column: 2 / span 10;
    }
  }

  &__filters {
    height: 100%;
    z-index: get-z-index('panel', 'focus-filter');

    @include mq($until: s) {
      position: absolute;
      top: -60px;
      width: 100%;
      height: auto;
      background: color(neutral, 10);
      box-shadow: 0 2px 20px 0 rgba(135, 135, 135, 0.3);
      border-radius: 40px;
      transform: scale(0);
      transform-origin: center;
      visibility: hidden;
      transition: visibility 0ms linear 200ms, transform 200ms ease;
      min-height: 176px;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .teaser-focus-filter__toggle[aria-expanded='true'] + & {
      transform: scale(1);
      visibility: visible;
      transition: visibility 0ms linear, transform 200ms ease;
    }
  }

  &__groups {
    @include mq(s) {
      height: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  &__group-item {
    margin: space(m) 0;
  }

  &__group-heading {
    position: relative;
    z-index: get-z-index('heading', 'focus-filter');
  }

  &__toggle,
  &__group-btn {
    color: color(primary);
    text-transform: uppercase;
    cursor: pointer;
  }

  &__group-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &:after {
      content: '';
      width: 0;
      height: 0;
      margin-left: space(xs);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 8px solid color(primary);
    }

    &[aria-expanded='true'] {
      &:before {
        content: '';
        position: absolute;
        top: space(l);
        width: calc(100% - 60px);
        height: 1px;
        background: color(primary);

        @include mq(s) {
          width: 100%;
        }
      }

      &:after {
        transform: rotate(180deg);
      }
    }
  }

  &__group-panel {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: space(l) 30px 0 30px;
    z-index: get-z-index('panel', 'focus-filter');

    @include mq(s) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: color(neutral, 10);
      box-shadow: 0 20px 50px -12px rgba(135, 135, 135, 0.3);
      padding: calc(72px + #{space(l)}) 11% space(xl) 11%;
      border-radius: 40px;
      flex-direction: row;
      flex-wrap: wrap;
    }

    > * {
      margin: space(xs) space(xs) space(xs) 0;

      @include mq(s) {
        margin: space(xs) space(s) space(xs) 0;
      }
    }

    &[hidden] {
      display: none;
    }
  }

  &__reset {
    align-self: flex-end;

    @include mq(s) {
      align-self: center;
    }
  }

  &__apply {
    position: absolute;
    bottom: -60px;
    left: 0;
    width: 100%;
    background: color(neutral, 10);
    padding-top: 35px;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;

    button {
      width: 100%;
    }

    @include mq(s) {
      left: 50%;
      bottom: -26px;
      padding-top: 0;
      transform: translateX(-50%);
      width: auto;
      background: transparent;
    }

    @include mq(m) {
      bottom: -40px;
    }
  }

  &__toggle {
    display: block;
    width: 100%;
    height: 100%;

    @include mq(s) {
      display: none;
    }
  }

  &__regions-filter {
    width: 100%;
  }

  &__output {
    z-index: get-z-index('output', 'focus-filter');
    grid-column: 1 / span 6;

    @include mq(m) {
      grid-column: 3 / span 8;
    }

    &-filters,
    &-empty {
      margin-top: space(l);
    }

    &-filters,
    &-active-filters {
      display: flex;

      @include mq(s) {
        align-items: center;
      }
    }

    &-filters {
      flex-direction: column;

      > p {
        flex-shrink: 0;
        align-self: flex-start;
        margin: space(xs) space(s) space(xs) 0;
      }

      @include mq(s) {
        flex-direction: row;
      }
    }

    &-active-filters {
      flex-wrap: wrap;

      > * {
        margin: space(xs) space(s) space(xs) 0;
      }
    }

    &-empty {
      text-align: center;

      p {
        margin-top: space(m);
      }
    }
  }
}
