.gallery {
  position: relative;
  @include get-grid();

  &__item img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &__slider {
    width: 100%;
    grid-column: 1 / span 6;
    grid-row: 1;

    @include mq(m) {
      grid-column: 2 / span 8;
    }
  }


  &__slide {
    &.portrait {
      width: 44.5%;

      .gallery__item {
        display: grid;
        grid-column-gap: 30px;
        grid-template-columns: repeat(6, minmax(0, 1fr));

        @include mq(m) {
          grid-template-columns: repeat(8, minmax(0, 1fr));
        }
      }

      .gallery__image {
        aspect-ratio: 2/3;
        grid-column: 1 / span 3;

        @include mq(s) {
          grid-column: 2 / span 3;
        }

        @include mq(m) {
          width: 100%;
          grid-column: 3 / span 4;
        }
      }
    }

    .gallery__item {
      @include mq(m) {
        width: 100%;
      }
    }


    display: flex !important;
    align-items: flex-end !important;

  }

  &__image {
    position: relative;
    overflow: hidden;
    display: block;
    background: color(neutral, 30);
    aspect-ratio: 3/2;
  }

  &__captions {
    grid-column: 1 / span 4;
    grid-row: 2;

    @include mq(s) {
      grid-column: 2 / span 3;
    }

    @include mq(m) {
      grid-column: 4 / span 6;
    }
  }

  &__caption:not(:first-child) {
    display: none;
  }

  &__caption {
    margin-top: space(s);
  }

  &__controls {
    color: color(primary);
    grid-column: 5 / span 2;
    grid-row: 2;
    grid-row: 2;
    min-width: 96px;
    max-width: 96px;
    align-self: start;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    gap: space(s);
    margin-top: space(s);

    @include mq(m) {
      grid-column: 10 / span 2;
      grid-row: 1;
      align-self: end;
      margin-top: 0;
    }
  }

  &__pagination {
    flex-basis: 100%;
    text-align: center;
  }
}
