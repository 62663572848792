.survey-question {
  @include get-grid(
    (
      default: 6,
      m: 8
    ),
    false
  );

  &__subline {
    grid-column: 1 / -1;
    margin-bottom: space(m);

    @include mq(m) {
      margin-bottom: space(l);
    }
  }

  &__options {
    display: flex;
    flex-wrap: wrap;
    gap: space(s);
    grid-column: 1 / -2;
    margin-bottom: space(m);

    @include mq(m) {
      grid-column: 1 / -1;
    }
  }

  &__hint {
    grid-column: 1 / -1;
  }
}
