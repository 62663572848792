.navigation {
  position: relative;
  z-index: get-z-index('header');

  ul {
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    li:before {
      content: none;
    }
  }

  &__wrapper {
    position: relative;
    height: 80px;
    background: color(neutral, 10);
    @include get-grid-outer-gaps();

    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    @include mq(m) {
      height: 120px;
      padding: 0 space(l);
    }

    @include mq(l) {
      padding: 0 space(l);
    }

    @include mq(xl) {
      padding: 0 space(xl);
    }
  }

  &__logo {
    position: relative;
    width: 122px;
    height: 57px;
    display: block;

    @include mq(m) {
      width: 174px;
      height: 77px;
    }

    svg {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &__bar {
    opacity: 1;
    visibility: visible;
    transition: all 300ms ease;

    & > &-item {
      display: none;

      @include mq(m) {
        display: flex;
      }

      &--mobile-menu {
        display: flex;

        @include mq(m) {
          display: none;
        }
      }
    }

    & > &-item + &-item {
      margin-left: space(l);
    }
  }

  &__bar-fixed {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    top: space(s);
    right: 30px;
    left: 30px;
    width: calc(100% - 60px);
    height: 80px;
    background: color(neutral, 10);
    box-shadow: 0 2px 20px 0 rgba(135, 135, 135, 0.2);
    border-radius: 40px;
    padding: 0;
    z-index: get-z-index('fixed-nav', 'header');
    transform: scaleX(0);
    transform-origin: right;
    transition: all 400ms ease;

    @include mq(s) {
      width: auto;
      min-width: 350px;
      right: 30px;
      left: auto;
    }

    @include mq(m) {
      top: 20px;
      right: space(l);
    }

    @include mq(xl) {
      right: space(xl);
    }

    &-circle {
      position: fixed;
      top: space(s);
      right: 30px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: color(neutral, 10);
      box-shadow: 0 2px 20px 0 rgba(135, 135, 135, 0.2);
      transform: scale(0);
      transition: transform 400ms ease 350ms;
      z-index: get-z-index('fixed-nav', 'header');

      @include mq(m) {
        top: 20px;
        right: space(l);
      }

      @include mq(xl) {
        right: space(xl);
      }
    }

    & > .navigation__bar-item + .navigation__bar-item {
      margin-left: -8%;

      @include mq(xs) {
        margin-left: 0;
      }
    }

    & .navigation__link {
      width: 80px;
      height: 80px;

      span {
        display: none;
        @include mq(xs) {
          display: block;
        }
      }

      &[aria-expanded='true'] {
        background: color(primary);
        color: color(neutral, 10);
      }
    }
  }

  &__layer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: color(neutral, 30);
    box-shadow: 0 2px 20px 0 rgba(135, 135, 135, 0.3);
    visibility: hidden;
    transform: translateX(100%);
    transition: transform 300ms ease 300ms, visibility 0ms linear 600ms;
    z-index: get-z-index('layer', 'header');

    @include mq(s) {
      width: 445px;
      transform: translateX(445px);
    }

    @include mq(m) {
      width: 500px;
      transform: translateX(500px);
    }
  }

  &__layer-inner {
    position: relative;
    display: flex;
    height: 100%;
    padding-top: 100px;
    overflow: hidden;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms ease;
  }

  &__main-nav {
    margin: space(m) 30px;
    width: 100%;

    @include mq(s) {
      width: 350px;
      margin: space(m) 30px space(m) auto;
    }

    @include mq(m) {
      margin-right: space(l);
    }

    @include mq(xl) {
      margin-right: space(xl);
    }

    ul {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: space(m);
    }

    > ul {
      padding-left: space(xs);
    }
  }

  &__language-switch {
    text-align: center;
    margin-bottom: space(s);

    @include mq(m) {
      margin-bottom: space(l);
    }
  }

  &__language-link + &__language-link span {
    border-left: 1px solid color(primary);
    margin-left: 4px;
    padding-left: 9px;
  }

  &__main-nav-button span:after {
    content: ' ···';
  }

  &__main-nav nav {
    position: relative;
    width: 100%;
    height: 100%;

    li + li {
      margin-top: space(m);
    }
  }

  &__main-nav-list {
    &--hidden {
      transition: visibility 400ms linear;
      visibility: hidden;
    }
  }

  &__sub-menu {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: color(neutral, 30);
    visibility: hidden;
    transform: translateX(500px);
    transition: transform 400ms ease, visibility 0s linear 400ms;
    z-index: get-z-index('layer', 'header');
  }

  &__main-nav-list &__sub-menu {
    padding-left: 32px;

    .navigation__sub-menu {
      padding-left: 0;
      width: calc(100% - 32px);
    }
  }

  &__main-nav-button[aria-expanded='true'] {
    & + .navigation__sub-menu {
      transition: transform 400ms ease;
      transform: translateX(0);
      visibility: visible;
    }
  }

  &__main-nav-back-button {
    margin-bottom: space(m);
    margin-left: -32px;
  }

  &__main-nav-sub-headline {
    margin-bottom: space(s);
  }

  &__close {
    position: fixed;
    bottom: 30px;
    right: 30px;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms ease;

    @include mq(s) {
      top: space(s);
      right: 405px;
      bottom: auto;
    }

    @include mq(m) {
      top: 20px;
      right: 460px;
    }
  }

  &--expanded,
  &--detached {
    .navigation__bar-fixed-circle {
      transform: scale(1);
      transition: transform 400ms ease;
    }

    .navigation__bar-fixed {
      opacity: 1;
      transform: scaleX(1);
      visibility: visible;
      transition: transform 400ms ease 350ms;
    }
  }

  &--expanded {
    .navigation__bar {
      opacity: 0;
      visibility: hidden;
    }

    // .navigation__bar-fixed {
    //   :not([data-whatintent='touch']) & {
    //     margin-right: 15px;
    //   }
    // }
    .navigation__layer {
      visibility: visible;
      transform: translateX(0);
      transition: transform 300ms ease;
    }

    .navigation__layer-inner,
    .navigation__close {
      opacity: 1;
      visibility: visible;
      transition-delay: 500ms;
    }

    .navigation__close {
      z-index: get-z-index('nav-close', 'header');
    }
  }

  #mainNavigation,
  #login,
  #search,
  #watchlist {
    display: none;
  }

  &[data-active-layer='mainNavigation'] #mainNavigation {
    display: block;
  }

  &[data-active-layer='search'] {
    .navigation__layer {
      background: color(neutral, 10);
    }

    #search {
      display: block;
    }
  }

  &[data-active-layer='login'] #login {
    display: block;
  }

  &[data-active-layer='watchlist'] {
    .navigation__layer {
      background: color(neutral, 10);
    }

    #watchlist {
      display: block;
    }
  }

  &__link[aria-controls='watchlist'] {
    &:before {
      content: attr(data-notification-count);
      position: absolute;
      top: -5px;
      left: 10px;
      width: 15px;
      height: 15px;
      background: color(primary);
      border-radius: 50%;
      color: color(neutral, 10);
      font-size: 10px;
      font-weight: bold;
      line-height: 20px;
      text-align: center;
      line-height: 15px;
      transition: all 300ms ease;
      opacity: 0;
    }

    &.button--direction-column:before {
      top: 15px;
      left: 50%;
    }

    &[data-notification-count]:before {
      opacity: 1;
    }
  }
}
