.survey-start-container {
  @include get-grid();

  border-bottom: 1px color(neutral) solid;
  margin-bottom: space(m);
  padding-bottom: space(m);

  &__headline {
    border-bottom: 1px color(neutral) solid;
    grid-column: 1 / -1;
    margin-bottom: space(m);
    padding: space(m) 0;
    text-align: center;
  }

  &__left {
    border-bottom: 1px color(neutral) solid;
    grid-column: 1 / span 6;
    margin-bottom: space(m);
    min-width: 0; // Prevent Grid Blowout. See https://css-tricks.com/preventing-a-grid-blowout/
    position: relative;

    @include mq(m) {
      border-bottom: 0;
      border-right: 1px color(neutral) solid;
      margin-bottom: 0;
    }
  }

  &__right {
    grid-column: 1 / span 6;
    min-width: 0; // Prevent Grid Blowout. See https://css-tricks.com/preventing-a-grid-blowout/

    @include mq(m) {
      grid-column: 7 / span 6;
    }
  }

  &--single &__right {
    @include mq(m) {
      grid-column: 2 / span 10;
    }
  }
}
