.teaser-regions {
  position: relative;
  @include get-grid();

  &__error {
    grid-column: 1 / span 6;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 3 / span 8;
    }
  }

  &__teasers {
    grid-column: 1 / span 6;

    @include mq(m) {
      grid-column: 2 / span 10;
    }

    @include get-grid(
      (
        default: 6,
        s: 6,
        m: 10
      ),
      false
    );
  }

  &__headline {
    background: color(neutral, 40);
    grid-column: 1 / span 6;
    margin: space(m) -30px;
    padding: 4px 30px;

    @include mq(s) {
      margin: space(m) 0;
      padding: 4px space(m);
    }

    @include mq(m) {
      grid-column: 1 / span 10;
    }
  }

  &__teaser {
    grid-column: 1 / span 6;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 2 / span 8;
    }

    & + & {
      margin-top: space(m);
    }

    &-image {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      margin-right: space(m);

      @include mq(s) {
        width: 80px;
        height: 80px;
      }
    }

    svg {
      color: color('primary');
      margin-top: -2px;
    }

    h3 {
      display: block;
      transition: transform 0.3s ease-in-out;
    }

    &:hover,
    &:focus,
    &:active {
      h3 {
        transform: scale(1.01);
      }
    }

    &:hover h3 {
      color: color(primary, 70);
    }

    &:active h3 {
      color: color(neutral, 90);
    }

    &:focus-visible h3 {
      color: color(interaction);
      outline: 0;
    }
  }
}

.regions-filter {
  grid-column: 1 / span 6;
  margin-bottom: space(l);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include mq(s) {
    flex-direction: row;
    align-items: center;
  }

  @include mq(m) {
    grid-column: 2 / span 6;
    margin-bottom: space(xl);
  }

  &__geo-button {
    margin-bottom: space(m);
    flex-shrink: 0;

    span {
      margin-top: space(xs);
    }

    @include mq(s) {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }

  &__search {
    position: relative;
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;

    svg {
      pointer-events: none;
    }

    @include mq(m) {
      border-radius: 60px;
      height: 80px;
    }

    &-input {
      border: 1px solid color(primary);
      width: 100%;
      height: 100%;
      border-radius: 42px;
      padding: 0 120px 0 space(l);
      @include typography(form-label);

      @include mq(m) {
        border-radius: 60px;
        padding: 0 120px 0 space(xl);
      }

      &::placeholder {
        text-transform: uppercase;
      }
    }

    &-input-icon {
      position: absolute;
      color: color(primary);
      left: space(s);

      @include mq(m) {
        left: space(m);
      }
    }

    &-select {
      appearance: none;
      border: 1px solid color(primary);
      background: color(neutral, 10);
      height: 100%;
      border-radius: 42px;
      margin-left: -118px;
      padding: 0 space(l) 0 space(m);
      @include typography(button);

      color: color(primary);
      text-transform: uppercase;

      @include mq(m) {
        border-radius: 60px;
        padding: 0 space(l) 0 space(m);
      }
    }

    &-select-icon {
      position: absolute;
      color: color(primary);
      right: space(s);

      @include mq(m) {
        right: 20px;
      }
    }
  }
}
