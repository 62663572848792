[style*='--ratio'] {
  box-sizing: border-box;
  height: 0;
  padding-bottom: calc(100% / var(--ratio));
  position: relative;

  > * {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
