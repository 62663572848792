.paywall {
  position: relative;
  background: color(neutral, 30);
  margin-top: calc(#{space(l)} * -1);

  @include mq(m) {
    margin-top: calc(#{space(xl)} * -1);
  }

  &:before {
    content: '';
    position: absolute;
    top: -150px;
    left: 0;
    right: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &__content {
    @include get-grid();
    margin: 0 auto;
    max-width: 1380px;
    width: 100%;
    padding-top: space(l);
    padding-bottom: space(l);
  }

  &__content-inner {
    grid-column: 1 / span 6;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 3 / span 7;
    }
  }

  .richtext {
    margin-top: space(m);
  }

  &__register {
    margin-top: space(l);
  }

  &__login {
    margin-top: space(m);
  }
}
