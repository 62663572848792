.teaser-recent {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-column: span 6;
  border-top: 1px solid color(neutral, 50);
  padding-bottom: space(l);
  transition: 0.4s ease-in-out;

  &:last-child {
    border-bottom: 1px solid color(neutral, 50);
  }

  @include mq(768px) {
    padding-bottom: 0;
    flex-direction: row;
    align-items: center;
  }

  @include mq(m) {
    grid-column: span 12;
  }

  &__image {
    margin-top: 3px;
    margin-left: -30px;
    width: calc(100% - 30px);
    background-color: color(neutral, 30);

    @include mq(768px) {
      margin-left: 0;
      width: 100%;
    }
  }

  &__left {
    color: color(primary);

    @include mq(768px) {
      width: 33%;
      text-align: center;
    }
  }

  &__right {
    @include mq(768px) {
      width: 70%;
      margin-left: space(l);
      padding: space(l) 0;
    }
  }

  &__date-icon {
    width: 86px;
    height: 72px;
    margin-bottom: space(xs);

    @include mq(m) {
      width: 100px;
      height: 83px;
    }
  }

  &__event-controls {
    box-shadow: 0 2px 20px 0 rgba(135, 135, 135, 0.25);
    background: color(neutral, 10);
    border-radius: 40px;
    padding: space(s);
    margin-bottom: -20px;
    margin-top: space(xl);
    width: max-content;
    margin-left: auto;

    display: flex;
    align-items: center;
    gap: space(s);

    form {
      display: inline-flex;
    }

    @include mq(m) {
      margin-right: space(m);
    }
  }

  &__tags {
    margin-bottom: space(m);
    display: none;

    @include mq(768px) {
      display: block;
    }

    &--mobile {
      display: block;
      margin-bottom: space(l);

      @include mq(768px) {
        display: none;
      }
    }
  }

  &__location {
    display: flex;
    align-items: center;
    margin-top: space(s);
    text-transform: uppercase;

    @include mq(768px) {
      justify-content: center;
    }
  }

  &__location-icon {
    margin-right: space(xs);
  }

  &__watchlist-button {
    position: absolute;
    top: space(m);
    right: 0;

    @include mq(768px) {
      position: relative;
      top: auto;
      right: auto;
      flex-shrink: 0;
      margin-left: space(s);
      margin-top: space(m);
      align-self: flex-start;
    }

    @include mq(m) {
      margin-top: space(l);
      margin-right: space(m);
    }
  }

  &__link {
    h3 {
      transition: transform 0.3s ease-in-out;
    }

    &:hover,
    &:focus,
    &:active {
      h3 {
        transform: scale(1.01);
      }
    }

    &:hover {
      color: color(primary, 70);
    }

    &:active {
      color: color(neutral, 90);
    }

    &:focus-visible {
      color: color(interaction);
      outline: 0;
    }

    span {
      padding-top: space(s);
    }
  }

  &__divider {
    &--top,
    &--bottom {
      position: absolute;
      height: 6px;
    }

    &--top {
      top: -3px;
    }

    &--bottom {
      bottom: -3px;
    }
  }

  &__reading-time {
    margin-top: space(s);
  }

  &--events &__left {
    padding: space(m) 0;

    @include mq(768px) {
      padding: space(l) space(m);
      border-right: 1px dashed color(primary);
    }

    // @include mq(m) {
    //   padding: space(xl) space(m);
    // }
  }

  // &--events &__right {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  //   height: 100%;
  // }

  &--articles &__left {
    display: flex;
    flex-direction: column-reverse;

    @include mq(768px) {
      flex-direction: row;
      align-self: flex-start;
    }
  }

  &--articles &__left &__tags {
    position: relative;
    margin-top: -15px;
    z-index: 1;
  }

  &--articles &__link {
    .link {
      transition: transform 0.3s ease-in-out;

      span {
        top: -4px;
      }

      svg {
        fill: color(primary);
      }
    }

    &:hover,
    &:focus,
    &:active {
      .link {
        transform: scale(1.01);
      }
    }
  }

  &[data-animate='false'] {
    opacity: 0;
    transform: translateY(80px);
  }

  &[data-animate='true'] {
    opacity: 1;
    transform: translateY(0);
  }
}
