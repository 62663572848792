.filter-list {
  display: flex;
  margin: space(m) space(s);
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: space(xs);

  &__item {
    border-radius: space(s);
    cursor: pointer;
    height: 26px;
    padding: 0 space(s);
    color: color(primary);
    text-transform: uppercase;
    position: relative;
    flex-shrink: 0;

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
      color: color(neutral, 70);

      &:before {
        background: color(neutral, 70);
      }
    }

    &:before {
      height: 1px;
      content: '';
      background: color(primary);
      position: absolute;
      bottom: 1px;
      left: 15px;
      right: 15px;
    }

    &--active {
      background: color(primary);
      color: color(neutral, 10);
    }
  }
}
