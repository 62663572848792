.icon {
  &--tiny {
    height: space(s);
    width: space(s);
  }

  &--small {
    height: 24px;
    width: 24px;
  }

  &--large {
    height: 40px;
    width: 40px;
  }
}
