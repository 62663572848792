.event-stage {
  position: relative;
  margin-bottom: space(l);

  @include mq(m) {
    margin-bottom: space(xl);
  }

  &__media-wrapper {
    @include get-grid((), false);

    margin-bottom: space(xs);
    padding-right: 30px;

    @include mq(m) {
      padding-right: 0;
    }
  }

  &__image {
    grid-column: 1 / span 6;
    grid-row: 1;

    @include mq(s) {
      --ratio: 2 !important;
    }

    @include mq(m) {
      grid-column: 1 / span 9;
    }
  }

  &__line {
    grid-column: 1 / span 6;
    height: 100px;
    background-position: top;
    background-size: 20px 1px;
    background-repeat: repeat-x;
    background-image: linear-gradient(
      to right,
      color(primary) 33%,
      rgba(255, 255, 255, 0) 0%
    );

    @include mq(m) {
      grid-column: 1 / span 9;
      height: 150px;
    }
  }

  &__tags {
    grid-column: 1 / span 6;
    align-self: end;
    margin-bottom: 20px;
    margin-left: 30px;
    grid-row: 1;

    @include mq(s) {
      grid-column: 2 / span 5;
      margin-left: -9%;
    }

    @include mq(m) {
      grid-column: 10 / span 3;
      margin-bottom: 0;
      margin-left: 0;
    }

    .event-stage--has-no-image & {
      grid-row: auto;

      @include mq(m) {
        grid-row: 1;
      }
    }
  }

  &__content {
    max-width: 1380px;
    margin: 0 auto;
    @include get-grid();

    > * {
      @include mq(s) {
        margin-left: -13%;
      }

      @include mq(m) {
        margin-left: 0;
      }
    }
  }

  &__breadcrumb {
    grid-column: 1 / span 6;
    margin-bottom: space(l);

    @include mq(s) {
      grid-column: 2 / span 5;
    }

    @include mq(m) {
      grid-column: 2 / span 7;
    }

    .event-stage--has-no-image & {
      top: -150px;
      margin-bottom: 0;
    }
  }

  &__meta {
    position: relative;
    grid-column: 1 / span 6;

    @include mq(s) {
      grid-column: 2 / span 5;
    }

    @include mq(m) {
      grid-column: 2 / span 7;
    }

    > span + span:before {
      content: ' / ';
    }
  }

  &__headline {
    position: relative;
    grid-column: 1 / span 6;
    margin-top: space(m);

    @include mq(s) {
      grid-column: 2 / span 5;
    }

    @include mq(m) {
      grid-column: 2 / span 9;
    }

    h1 {
      color: color(primary);
    }
  }

  &__watchlist-button {
    margin-top: space(m);

    @include mq(m) {
      position: absolute;
      left: -75px;
      top: 12px;
      margin-top: 0;
    }

    @include mq(l) {
      top: 18px;
    }
  }

  &__subline {
    margin-top: space(s);

    @include mq(m) {
      margin-top: space(m);
    }
  }

  &__organizers {
    display: flex;
    flex-direction: column;
    margin-top: space(m);

    @include mq(m) {
      flex-direction: row;
    }

    &-inner {
      margin-top: space(xs);

      @include mq(m) {
        margin-top: 0;
        margin-left: space(xs);
      }

      > a {
        display: block;
        text-decoration: underline;
        text-decoration-thickness: from-font;
      }
    }
  }

  &__event-details,
  &__intro {
    padding-left: space(s);
    position: relative;
    grid-column: 1 / span 6;
    margin: space(l) 0 0;

    @include mq(s) {
      grid-column: 2 / span 4;
      margin: space(xl) 0 0;
      margin-left: -13%;
      padding-left: 13%;
    }

    @include mq(m) {
      margin-left: 0;
      grid-column: 3 / span 7;
    }

    @include mq(m) {
      padding-left: 0;
    }
  }

  &__intro {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      height: 100%;
      background: color(primary);

      @include mq(m) {
        left: -15%;
      }
    }

    p {
      margin: 0;
    }
  }
}
