.survey-option {
  align-items: center;
  background: color(neutral, 10);
  border-radius: 40px;
  border: 1px color(primary) solid;
  color: color(primary);
  cursor: pointer;
  display: flex;
  gap: space(s);
  min-height: 52px;
  padding: space(xs) 6px space(xs) space(m);
  width: 100%;

  @include mq(m) {
    display: inline-flex;
    height: space(xl);
    padding-right: 20px;
    width: auto;
  }

  &--checked {
    background: color(primary);
    color: color(neutral, 10);
  }

  &__input {
    appearance: none;
  }

  &__input-check {
    border: 1px currentColor solid;
    border-radius: 40px;
    width: 40px;
    height: 40px;

    svg {
      opacity: 0;
    }
  }

  &--checked &__input-check svg {
    opacity: 1;
  }

  &__label {
    flex: 1;
    text-transform: uppercase;
  }
}
