$font-family-default: 'Roboto', Arial, Helvetica, sans-serif;
$font-family-slab: 'Roboto Slab', Arial, Helvetica, sans-serif;
$font-family-condensed: 'Roboto Condensed', Arial, Helvetica, sans-serif;

$typography: (
  headline-1: (
    fontSizes: 53 95,
    lineHeights: 1.06 0.97,
    letterSpacings: 0 0,
    weight: 700,
    family: $font-family-slab
  ),
  headline-2: (
    fontSizes: 36 76,
    lineHeights: 1.22 1.11,
    letterSpacings: 0 0,
    weight: 700,
    family: $font-family-slab
  ),
  headline-3: (
    fontSizes: 26 40,
    lineHeights: 1.38 1.2,
    letterSpacings: 0 0,
    weight: 700,
    family: $font-family-slab
  ),
  headline-3-sans: (
    fontSizes: 26 40,
    lineHeights: 1.38 1.2,
    letterSpacings: 0 0,
    weight: 500,
    family: $font-family-default
  ),
  headline-3-sans-light: (
    fontSizes: 26 40,
    lineHeights: 1.38 1.2,
    letterSpacings: 0 0,
    weight: 300
  ),
  headline-4: (
    fontSizes: 20 30,
    lineHeights: 1.4 1.33,
    letterSpacings: 0 0,
    weight: 300
  ),
  headline-5: (
    fontSizes: 20 21,
    lineHeights: 1.4 1.42,
    letterSpacings: 0.2 0.2,
    weight: 700,
    family: $font-family-slab
  ),
  leadin: (
    fontSizes: 20 26,
    lineHeights: 1.6 1.38,
    letterSpacings: 0 0,
    weight: 300
  ),
  copy-bold: (
    fontSizes: 16 20,
    lineHeights: 1.63 1.6,
    letterSpacings: 0 0,
    weight: 500
  ),
  copy-bold-slab: (
    fontSizes: 16 16,
    lineHeights: 1.63 1.3,
    letterSpacings: 0 0,
    weight: 700,
    family: $font-family-slab
  ),
  copy: (
    fontSizes: 16 20,
    lineHeights: 1.63 1.6,
    letterSpacings: 0 0,
    weight: 300
  ),
  copy-small: (
    fontSizes: 12 14,
    lineHeights: 1.67 1.71,
    letterSpacings: 0 0.2,
    weight: 400
  ),
  copy-label: (
    fontSizes: 12 12,
    lineHeights: 1.67 1.67,
    letterSpacings: 0.3 0.3,
    weight: 400,
    family: $font-family-condensed
  ),
  quote: (
    fontSizes: 20 28,
    lineHeights: 1.6 1.43,
    letterSpacings: 0 0,
    weight: 300,
    family: $font-family-slab
  ),
  button: (
    fontSizes: 16 16,
    lineHeights: 1.25 1.25,
    letterSpacings: 0.8 0.8,
    weight: 700,
    family: $font-family-condensed
  ),
  form-label: (
    fontSizes: 16 16,
    lineHeights: 1.25 1.25,
    letterSpacings: 1 1,
    weight: 400,
    family: $font-family-condensed
  ),
  form-hint: (
    fontSizes: 14 14,
    lineHeights: 1.14 1.14,
    letterSpacings: 0.2 0.2,
    weight: 400,
    family: $font-family-condensed
  )
);
