.stage-tags {
  position: relative;

  &__headline {
    display: none;

    @include mq(m) {
      display: block;
    }
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;

    > * {
      margin-top: space(xs);
      margin-right: space(xs);
    }
  }

  &__toggle {
    appearance: none;
    border: none;
  }

  &__layer {
    transform: scale(0);
    transform-origin: center;
    visibility: hidden;
    transition: visibility 0ms linear 200ms, transform 200ms ease;
    margin-right: 30px;

    @include mq(m) {
      margin-left: -80px;
    }

    &[aria-hidden='false'] {
      transform: scale(1);
      visibility: visible;
      transition: visibility 0ms linear, transform 200ms ease;
    }
  }
}
