.text {
  color: inherit;
  font-family: $font-family-default;
  margin: 0;
  hyphens: auto;

  @each $name, $value in $typography {
    // Loop over typography keys and build text type
    &--#{$name} {
      @include typography(#{$name});
    }
  }

  &--bold {
    font-weight: 500;
  }
}
