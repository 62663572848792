.pull-quote {
  position: relative;
  @include get-grid();

  margin: 0;

  cite {
    font-style: normal;
  }

  &__icon {
    grid-column: 1 / span 1;
    grid-row: 1;
    justify-self: end;

    @include mq(s) {
      grid-column: 2 / span 1;
      justify-self: start;
    }

    @include mq(m) {
      grid-column: 3 / span 1;
      justify-self: end;
    }
  }

  &__icon,
  &__quotation {
    grid-row: 1;
  }

  &__quotation,
  &__author {
    grid-column: 2 / span 5;

    @include mq(s) {
      grid-column: 2 / span 4;
      margin-left: 9%;
    }

    @include mq(m) {
      grid-column: 4 / span 6;
      margin-left: 0;
    }
  }

  &__quotation {
    margin-top: -9px;
  }

  &__author {
    margin-top: space(s);

    @include mq(m) {
      margin-top: space(m);
    }

    a {
      text-decoration: underline;
      text-decoration-thickness: from-font;
    }
  }
}
