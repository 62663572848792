.login {
  position: relative;
  width: 100%;

  &__mask {
    padding: 0 30px;

    @include mq(l) {
      padding: 0 space(l);
    }
  }

  &__form {
    .form__submit {
      justify-content: flex-start;

      button {
        min-width: 260px;
      }
    }
  }

  &__register {
    padding-bottom: space(l);

    &-copy {
      margin-top: space(s);
    }

    &-link {
      margin-top: space(m);
      min-width: 260px;
    }
  }

  &__user-navigation {
    $this: &;
    padding: space(m) 0;
    margin: space(m) 30px;
    width: 100%;

    @include mq(s) {
      width: 350px;
      margin: space(m) 30px space(m) auto;
    }

    @include mq(m) {
      margin-right: space(l);
    }

    @include mq(xl) {
      margin-right: space(xl);
    }

    &-links > * {
      display: block;

      + * {
        margin-top: space(m);
      }
    }

    nav {
      position: relative;
      width: 100%;
      height: 100%;
  
      li + li {
        margin-top: space(m);
      }
    }

    ul#{$this}-list {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: space(m);
    }

    &-logout {
      margin-top: space(xl);
      min-width: 260px;
    }
  }
}
