@import './javascripts/components/SurveyColumns/survey-columns';
@import './javascripts/components/SurveyContainer/survey-container';
@import './javascripts/components/SurveyCover/survey-cover';
@import './javascripts/components/SurveyLoadingInline/survey-loading-inline';
@import './javascripts/components/SurveyNavButton/survey-nav-button';
@import './javascripts/components/SurveyOption/survey-option';
@import './javascripts/components/SurveyQuestion/survey-question';
@import './javascripts/components/SurveyResult/survey-result';
@import './javascripts/components/SurveyResultContainer/survey-result-container';
@import './javascripts/components/SurveyStartContainer/survey-start-container';
@import './javascripts/components/SurveyStartSlidePlaceholder/survey-start-slide-placeholder';
@import './javascripts/components/SurveyStartTeaser/survey-start-teaser';

.survey {
  &--without-background .survey-container {
    background: color(neutral, 10);
  }
}
