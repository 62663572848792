.divider {
  position: relative;
  height: 6px;
  width: 100%;
  color: color(primary);
  background: color(neutral, 10);
  background-image: url("data:image/svg+xml,%3Csvg width='368' height='6' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M368.998 0h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005-1 .001.004 5h1l-.004-5Zm-8 .006h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005-1 .001.004 5h1l-.004-5Zm-8 .006h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005-1 .001.004 5h1l-.004-5Zm-8 .006h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005-1 .001.004 5h1l-.004-5Zm-8 .006h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005-1 .001.004 5h1l-.004-5Zm-8 .006h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005-1 .001.004 5h1l-.004-5Zm-8 .006h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005-1 .001.004 5h1l-.004-5Zm-8 .006h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005-1 .001.004 5h1l-.004-5Zm-8 .006h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005h-1l.004 5h1l-.004-5Zm-8 .005-1 .001.004 5h1l-.004-5Z' fill='%23E30613' fill-rule='nonzero' /%3E%3C/svg%3E");

  span {
    position: absolute;
    top: -3px;
    left: space(m);
    height: 10px;
    bottom: 0;
    z-index: 2;
    background: color(neutral, 10);
    color: color(primary);
    text-transform: uppercase;
    padding: 0 space(xs);
    line-height: 1;
  }

  &--pushed {
    span {
      left: 27%;

      @include mq(768px) {
        left: 34%;
      }

      @include mq(m) {
        left: 32.5%;
      }
    }
  }
}
