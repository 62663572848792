.facet-filter-date-range {
  gap: space(m);
  display: flex;
  flex-direction: column;

  @include mq(s) {
    flex-direction: row;
  }

  .input {
    width: 100%;
    display: flex;
    gap: space(xs);

    label {
      position: relative;
      left: auto;
      color: color(primary);
      transition: none;
    }
  }
}
