.iframe {
  @include get-grid();

  &__headline,
  &__wrapper {
    grid-column: 1 / span 6;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 3 / span 8;
    }
  }

  &__headline {
    margin-bottom: space(m);
  }

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  iframe {
    width: 100%;
    height: auto;
    aspect-ratio: var(--aspect);
    max-width: var(--max-width);
  }
}
