.image-upload {
  position: relative;
  margin: 0;

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__form {
    position: relative;
    display: flex;
    gap: space(m);
    flex-direction: column;


    &:first-of-type {
      margin-top: 30px;
    }

    @include mq(m) {
      flex-direction: row;
      align-items: center;
    }

    &--delete {
      margin-top: space(xs);
      margin-left: space(m);
    }
  }

  input {
    @include hide-visually();
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: space(m);


    @include mq(m) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    background: color(neutral, 30);
    border: 1px solid color(neutral, 30);
    margin-right: space(m);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    svg {
      color: color(primary);
    }
  }

  &__label {
    position: absolute;
    z-index: 1;
    color: color(neutral);
    display: block;
    text-transform: uppercase;
    top: -30px;
    left: space(m);
    line-height: 1;
    transition: top 0.2s ease-in-out;

    span > span {
      color: color(primary);
      padding-left: 4px;
    }
  }

  &__hint {
    margin-left: space(m);
    margin-top: space(xs);
    color: color(neutral);
  }

  &--form {
    @include get-grid;
  }

  &--form &__wrapper {
    grid-column: 1 / span 6;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 3 / span 7;
    }
  }

  &--landscape-image &__image {
    border-radius: 0%;
    width: 300px;
    height: 165px;


    img {
      object-fit: contain;
    }
  }

  &--portrait-image &__image {
    border-radius: 0%;
    width: 150px;
    height: 200px;

    img {
      object-fit: contain;
    }
  }
}
