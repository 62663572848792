.button {
  align-items: center;
  appearance: none;
  border-radius: 42px;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  padding: 0 space(m);
  position: relative;
  text-transform: uppercase;
  transition: color 200ms ease, background-color 200ms ease,
    border-color 200ms ease;
  outline: 0;
  gap: space(xs);

  &[disabled] {
    color: color(neutral, 50);
    user-select: none;
    cursor: not-allowed;
  }

  &:focus {
    [data-whatintent='keyboard'] & {
      border-color: color(interaction);
    }
  }

  &--primary {
    background: color(primary);
    border-color: color(primary);
    border-radius: space(xl);
    color: color(neutral, 10);
    min-width: 252px;
    height: 52px;

    @include mq(m) {
      height: 80px;
    }

    &:hover {
      background: color(primary, 70);
      border-color: color(primary, 70);
    }

    &:active {
      background: color(primary, 90);
      border-color: color(primary, 90);
    }

    &[disabled] {
      background: color(neutral, 30);
      border-color: color(neutral, 30);
    }
  }

  &--secondary {
    background: color('neutral', 10);
    border-color: color(primary);
    color: color(primary);
    min-width: 252px;
    height: 52px;

    @include mq(m) {
      height: 80px;
    }

    &:hover {
      border-color: color(primary, 70);
      color: color(primary, 70);
    }

    &:active {
      border-color: color(primary, 90);
      color: color(primary, 90);
    }

    &[disabled] {
      border-color: color(neutral, 30);
    }
  }

  &--tertiary {
    background: transparent;
    border: none;
    color: color(primary);
    padding: 0;

    &:hover {
      color: color(primary, 70);
    }

    &:active {
      color: color(primary, 90);
    }

    &:focus {
      [data-whatintent='keyboard'] & {
        border: 0;
        color: color(interaction);
      }
    }
  }

  &--quaternary {
    border: 0;
    background: color(neutral, 10);
    box-shadow: 0 2px 20px 0 rgba(135, 135, 135, 0.15);
    color: color(primary);
    min-width: 252px;

    &:hover {
      background: color(primary);
      color: color(neutral, 10);
    }

    &:focus {
      [data-whatintent='keyboard'] & {
        border: 1px solid color(interaction);
      }
    }
  }

  &--hidden-label {
    padding: 0;
    min-width: auto;
    width: 80px;
    height: 80px;
  }

  &__icon,
  .spinner {
    color: currentColor;
    fill: currentColor;
    flex-shrink: 0;
  }

  &--direction-column {
    flex-direction: column;
    gap: 0;
  }
}
