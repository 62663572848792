.survey-start-slide-placeholder {
  --width: 375;

  aspect-ratio: var(--width) / 300;
  display: grid;
  margin: auto;
  max-width: var(--width);
  place-items: center;
  width: 100%;

  @include mq(m) {
    --width: 450;
  }
}
