@font-face {
  font-display: swap;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/RobotoCondensed-Bold.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/RobotoSlab-Bold.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Roboto-Medium.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Roboto-Regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/RobotoCondensed-Regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/RobotoSlab-Light.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Roboto-Light.woff2') format('woff2');
}
