.advent-calendar {
  @include get-grid();

  &__headline,
  &__wrapper {
    grid-column: 1 / span 6;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 3 / span 7;
    }
  }

  &__headline {
    margin-bottom: space(m);
  }

  &__wrapper {
    min-height: 400px;
  }
}
