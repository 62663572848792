.third-party-content {
  position: relative;

  &__consent {
    display: none;
  }

  &__toggle {
    margin-top: space(xs);
  }

  &__doo-widget-fallback {
    margin-top: space(s);
  }

  &[data-js-consent='false'] {
    .third-party-content__consent {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: space(m) space(xs);
      background: color(neutral, 40);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include mq(m) {
        padding: space(m);
      }

      & > * + * {
        margin-top: space(m);
      }
    }
  }

  &[data-js-consent='true'] {
    .third-party-content__consent {
      display: none;
    }
  }
}
