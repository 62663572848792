@import './javascripts/components/Autocomplete/autocomplete';
@import './javascripts/components/FacetFilterCheckboxGroup/facet-filter-checkbox-group';
@import './javascripts/components/FacetFilterDateRange/facet-filter-date-range';
@import './javascripts/components/FacetFilterRegion/facet-filter-region';

.search-results {
  @include get-grid;

  .current-filters,
  .search-no-results,
  .filter-list,
  .facet-filter,
  .search-hits {
    grid-column: 1 / -1;

    @include mq(m) {
      grid-column: 3 / span 8;
    }
  }

  &__search-box {
    grid-column: 1 / -1;
    margin-bottom: space(m);

    @include mq(s) {
      grid-column: 2 / span 5;
      margin-left: -13%; // wtf article-stage
      margin-right: 6.5%;

      margin-bottom: space(xl);
    }

    @include mq(m) {
      grid-column: 3 / span 8;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .filter-list {
    margin: 0;
  }

  .filter-list {
    margin-bottom: space(m);
  }

  .divider {
    grid-column: 1 / -1;
  }

  .current-filters {
    margin-bottom: space(s);
  }
}
