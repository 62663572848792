.textarea {
  position: relative;
  margin-top: 30px;

  label {
    position: absolute;
    color: color(neutral);
    display: block;
    text-transform: uppercase;
    top: 16px;
    left: space(m);
    line-height: 1;
    transition: top 0.2s ease-in-out;

    @include mq(m) {
      top: 30px;
    }

    span > span {
      color: color(primary);
      padding-left: 4px;
    }
  }

  &__error {
    color: color(primary);
    display: none;
    margin-top: space('s');
    margin-left: space(m);
  }

  &--error &__error,
  textarea.user-error ~ &__error {
    display: block;
  }

  &--error label {
    color: color(warning);
  }

  &--error textarea {
    border-color: color(warning);
  }

  &--success label {
    color: color(success);
  }

  &--success textarea {
    border-color: color(success);
  }

  textarea {
    border: 1px solid color(primary);
    color: color(neutral);
    padding: space(m);
    background: color(neutral, 10);
    width: 100%;
    border-radius: 40px;
    min-height: 150px;

    &:focus {
      border-color: color(interaction);
    }

    &[disabled] {
      cursor: not-allowed;
      border-color: color(neutral, 50);
    }

    &::placeholder {
      color: color(neutral);
      opacity: 0;
    }
  }

  input:-webkit-autofill + label,
  textarea:not(:placeholder-shown) + label,
  textarea[placeholder=''] + label,
  textarea:focus + label {
    top: -30px;
  }

  textarea,
  textarea::placeholder {
    @include typography(copy);
  }
}
