.accordion {
  @include get-grid();

  width: 100%;
  margin: 0;

  dl,
  dt {
    margin: 0;
  }

  dt:not(:first-child) {
    border-top: 1px solid color(neutral, 90);
  }

  &__headline,
  &__item {
    grid-column: 1 / span 6;

    @include mq(s) {
      grid-column: 2 / span 4;
    }

    @include mq(m) {
      grid-column: 3 / span 7;
    }
  }

  &__headline {
    border-bottom: 1px solid color(neutral, 90);
    padding-bottom: space(m);
  }

  &__button {
    appearance: none;
    background: none;
    border: none;
    outline: none;
    padding: space(m) 0;
    text-align: left;
    width: 100%;

    @include get-grid(
      (
        default: 6,
        s: 4,
        m: 7
      ),
      false
    );

    svg {
      color: color(primary);
      pointer-events: none;
      grid-column: 1 / span 1;
      grid-row: 1;
    }

    span {
      margin-top: 4px;
      pointer-events: none;
      grid-column: 2 / span 5;
      grid-row: 1;

      @include mq(s) {
        grid-column: 2 / span 3;
        margin-left: -30px;
      }

      @include mq(m) {
        grid-column: 2 / span 6;
        margin-left: 0;
      }
    }

    &[aria-expanded='true'] {
      svg {
        path:last-child {
          transform: rotate(180deg);
          transform-origin: center;
        }
      }
    }

    &:focus {
      [data-whatintent='keyboard'] & {
        color: color(interaction);

        svg {
          color: color(interaction);
        }
      }
    }
  }

  &__panel {
    margin: 0;

    @include get-grid(
      (
        default: 6,
        s: 4,
        m: 7
      ),
      false
    );

    &[hidden] {
      display: none;
    }
  }

  &__block {
    margin-bottom: space(m);
    grid-column: 1 / span 6;
    padding-left: space(s);

    @include mq(s) {
      grid-column: 1 / span 4;
    }

    @include mq(m) {
      padding-left: 0;
      grid-column: 2 / span 6;
    }
  }

  &__block--button {
    margin-bottom: space(xl);
  }

  &__block--contacts {
    @include mq(s) {
      padding-left: 0;
    }

    @include mq(m) {
      grid-column: 1 / span 7;
    }

    .contacts__contact {
      @include get-grid(
        (
          default: 6,
          s: 4,
          m: 7
        ),
        false
      );

      &-inner {
        position: relative;
        grid-column: 1 / span 7;
        grid-row: 1;

        @include mq(s) {
          grid-column: 2 / span 3;
          margin-left: -30px;
        }

        @include mq(m) {
          margin-left: 0;
          grid-column: 2 / span 6;
        }
      }
    }

    .contacts__watchlist-button {
      position: absolute;
      top: 0;
      right: -11px;

      @include mq(s) {
        position: relative;
        grid-row: 1;
        grid-column: 1 / span 1;
        margin-left: -11px;
      }

      @include mq(m) {
        left: auto;
      }
    }
  }

  &__block--content-image {
    margin-bottom: space(l);

    .content-image {
      @include get-grid(
        (
          default: 6,
          s: 4,
          m: 6
        ),
        false
      );
    }

    .content-image--landscape {
      & > div {
        grid-column: 1 / span 6;

        @include mq(s) {
          grid-column: 1 / span 4;
        }

        @include mq(m) {
          grid-column: 1 / span 6;
        }
      }

      figcaption {
        grid-column: 2 / span 5;

        @include mq(s) {
          grid-column: 2 / span 3;
        }

        @include mq(m) {
          grid-column: 1 / span 6;
        }
      }
    }

    .content-image--portrait {
      & > div {
        grid-column: 2 / span 5;

        @include mq(s) {
          grid-column: 2 / span 3;
        }

        @include mq(m) {
          grid-column: 1 / span 4;
        }
      }

      figcaption {
        grid-column: 2 / span 5;

        @include mq(s) {
          grid-column: 2 / span 3;
        }

        @include mq(m) {
          grid-column: 1 / span 6;
        }
      }
    }
  }

  &__block--video {
    .video {
      padding: 0;
      @include get-grid(
        (
          default: 6,
          s: 4,
          m: 7
        ),
        false
      );

      .video__wrapper {
        grid-column: 1 / span 6;

        @include mq(s) {
          grid-column: 1 / span 4;
        }

        @include mq(m) {
          grid-column: 1 / span 7;
        }
      }

      .video__caption {
        grid-column: 2 / span 5;

        @include mq(s) {
          grid-column: 2 / span 3;
        }

        @include mq(m) {
          grid-column: 1 / span 6;
        }
      }
    }
  }

  &__block--pull-quote {

    .pull-quote {
      padding: 0;

      @include get-grid(
        (
          default: 6,
          s: 4,
          m: 7
        ),
        false
      );

      .pull-quote__icon {
        grid-column: 1 / span 1;
        justify-self: start;

        @include mq(s) {
          grid-column: 1 / span 1;
        }

        @include mq(m) {
          grid-column: 1 / span 1;

        }
      }

      .pull-quote__quotation,
      .pull-quote__author {
        grid-column: 1 / span 5;
        margin-left: 30px;

        @include mq(s) {
          grid-column: 1 / span 4;
        }

        @include mq(m) {
          grid-column: 1 / span 6;
        }
      }
    }
  }
}
