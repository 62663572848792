.watchlist {
  width: 100%;
  margin: 0;
  background: color(neutral, 10);

  &__empty {
    padding: space(m);

    & > * + * {
      margin-top: space(s);
    }
  }

  &__letter-divider {
    padding: space(xs) space(m);
    background: color(neutral, 40);
  }

  &__list {
    min-height: 200px;
  }

  &__list[data-filter='contacts'] article {
    background: none;
    border-bottom: 1px solid color(neutral, 40);
  }

  &__spinner {
    margin-top: space(m);
  }
}
