.input {
  position: relative;
  margin-top: 30px;

  label {
    position: absolute;
    color: color(neutral);
    display: block;
    text-transform: uppercase;
    top: 16px;
    left: space(m);
    line-height: 1;
    transition: top 0.2s ease-in-out;

    @include mq(m) {
      top: 30px;
    }

    span > span {
      color: color(primary);
      padding-left: 4px;
    }
  }

  button {
    position: absolute;
    right: space(s);
    top: 0px;
    width: 52px;
    height: 52px;

    @include mq(m) {
      width: 80px;
      height: 80px;
    }
  }

  input {
    border: 1px solid color(primary);
    color: color(neutral);
    padding: space(xs) space(m);
    background: color(neutral, 10);
    width: 100%;
    border-radius: 40px;
    height: 52px;

    @include mq(m) {
      height: 80px;
      padding: space(s) space(m);
    }

    &:focus {
      border-color: color(interaction);
    }

    &[disabled] {
      cursor: not-allowed;
      border-color: color(neutral, 50);
    }

    &::placeholder {
      color: color(neutral);
      opacity: 0;
    }
  }

  input:-webkit-autofill + label,
  input:not(:placeholder-shown) + label,
  input[placeholder=''] + label,
  input:focus + label {
    top: -30px;
  }

  input,
  input::placeholder {
    @include typography(copy);
  }

  &__hint {
    margin-left: space(m);
    margin-top: space(xs);
    color: color(neutral);
  }

  &__error {
    color: color(primary);
    display: none;
    margin-top: space(s);
    margin-left: space(m);
  }

  &--error &__error,
  input.user-error ~ &__error {
    display: block;
  }

  &--error {
    label {
      color: color(warning);
    }

    input {
      border-color: color(warning);
      color: color(warning);
    }
  }

  &--success label {
    color: color(success);
  }

  &--success {
    input {
      border-color: color(success);
    }
  }

  &--hidden {
    display: none;
  }

  &[data-js-module='input--password'] input {
    padding-right: 52px;

    @include mq(m) {
      padding-right: 80px;
    }
  }
}
