.content-blocks {
  &__block {
    margin: space(l) 0;

    @include mq(m) {
      margin: space(xl) 0;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__block--pull-quote + &__block--richtext h2 {
    margin-top: 36px;

    @include mq(m) {
      margin-top: 60px;
    }
  }

  &__block--teaser-container {
    @include mq(m) {
      margin: space(xxl) 0;
    }
  }

  &__block--button {
    @include get-grid;

    & > * {
      grid-column: 1 / span 4;

      @include mq(s) {
        grid-column: 2 / span 2;
      }

      @include mq(m) {
        grid-column: 3 / span 3;
      }
    }

    .button--tertiary {
      justify-content: flex-start;
    }
  }
}
