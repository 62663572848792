.partner-logos {
  @import '~keen-slider/keen-slider';

  &__grid {
    @include get-grid();
  }

  &__container {
    grid-column: 1 / span 6;
    min-width: 0;

    @include mq(m) {
      grid-column: 3 / span 7;
    }
  }

  &__headline {
    margin-bottom: space(m);
  }

  &__subline {
    margin-bottom: space(m);
  }

  &__logos-wrapper {
    position: relative;
    grid-column: 1 / span 6;
    min-width: 0;

    @include mq(m) {
      grid-column: 3 / span 7;
    }

    &::before,
    &::after {
      content: '';
      opacity: 0;
      position: absolute;
      top: 1px;
      height: 100%;
      width: 100px;
      pointer-events: none;
      z-index: 1;
      transition: opacity 0.4s ease;
    }

    &::before {
      left: -30px;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0) 0%,
        color(neutral, 10) 84%
      );
    }

    &::after {
      right: -30px;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 45%,
        color(neutral, 10) 84%
      );
    }
  }

  &--has-prev &__logos-wrapper::before {
    opacity: 1;
  }

  &--has-next &__logos-wrapper::after {
    opacity: 1;
  }

  &__logos-slider {
    list-style: none;
    margin: 0;
    padding: space(m) 0;
    border-top: 1px color(neutral, 90) solid;
  }

  &__logos-slide {
    flex: 0 0 auto;
    width: auto !important;
  }

  &__logo {
    display: block;
    filter: saturate(0);
    transition: filter 0.2s ease;

    &:hover {
      filter: none;
    }

    img {
      display: block;
      height: 40px;
      width: auto;
    }
  }

  &__prev-btn {
    display: none;

    @include mq(m) {
      grid-column: 2;
      grid-row: 1;
      place-content: center start;
    }
  }

  &--has-prev &__prev-btn {
    @include mq(m) {
      display: grid;
    }
  }

  &__next-btn {
    display: none;

    @include mq(m) {
      grid-column: 10;
      grid-row: 1;
      place-content: center end;
    }
  }

  &--has-next &__next-btn {
    @include mq(m) {
      display: grid;
    }
  }

  &__nav-btn {
    color: color(primary);

    &:hover {
      color: color(primary, 70);
    }

    &:active {
      color: color(primary, 90);
    }

    svg {
      background: color(neutral, 10);
      border-radius: 40px;
    }
  }
}
