.radio {
  &__label {
    display: block;
    margin-bottom: space(m);
    text-transform: uppercase;

    span > span {
      color: color(primary);
      padding-left: 4px;
    }
  }

  input {
    @include hide-visually();
  }

  &__item {
    align-items: center;
    cursor: pointer;
    display: flex;
    line-height: 1;
    margin-bottom: space(s);
    position: relative;

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &-custom-icon {
      border: 1px solid color(primary);
      border-radius: 100%;
      display: inline-block;
      height: 40px;
      margin-right: space(s);
      position: relative;
      width: 40px;

      &:after {
        background: color(primary);
        border-radius: 100%;
        content: '';
        display: none;
        height: 24px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
      }
    }
  }

  &__error {
    margin-top: space(s);
    display: none;
  }

  &--error &__label,
  &--error &__error {
    color: color(warning);
    display: block;
  }

  &--error &__item-custom-icon {
    border-color: color(warning);
  }

  &--success &__label {
    color: color(success);
  }

  &--success &__item-custom-icon {
    border-color: color(success);
  }

  input:checked ~ &__item-custom-icon {
    &:after {
      display: block;
    }
  }
}
