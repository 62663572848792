.search-teaser {
  position: relative;

  &:nth-of-type(even) {
    background: color(neutral, 30);
  }

  > a {
    display: flex;
    min-height: 120px;
    overflow: hidden;
    transition: height 0.2s ease-in-out;

    &:focus {
      [data-whatintent='keyboard'] & {
        border-color: color(interaction);
      }
    }

    &[aria-hidden='true'] {
      height: 0px;
    }
  }

  &__media {
    width: 120px;
    height: 120px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: color(primary);
    line-height: 1.2;

    svg {
      margin-bottom: space(xs);
      width: 31px;
      height: 26px;
    }
  }

  &__tags {
    margin-left: calc(#{space(l)} * -1);
    margin-bottom: space(xs);
    font-size: 0;

    & > * + * {
      margin-left: space(xs);
    }
  }

  &__content {
    padding: space(s) space(s) space(s) space(m);

    h3,
    p {
      hyphens: auto;
      color: color(neutral, 90);
    }
  }

  &__menu {
    position: absolute;
    top: 0;
    right: 4px;
    background: none;
    border: none;
    appearance: none;
    outline: none;
    color: color(primary);
    padding: space(xs) 0;
    cursor: pointer;
  }

  &__actions {
    position: absolute;
    top: -10px;
    z-index: 1;
    right: 0;
    background: color(neutral, 10);
    padding: 24px space(s);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    box-shadow: 0 2px 20px 0 rgba(135, 135, 135, 0.3);
    display: flex;
    transform: scaleX(0);
    transform-origin: center right;
    transition: transform 0.4s ease-in-out, visibility 0.4s 0.4s ease-in-out;
    visibility: hidden;

    &[aria-hidden='false'] {
      visibility: visible;
      transition: transform 0.4s ease-in-out;
      transform: scaleX(1);
    }

    button {
      background: none;
      border: none;
      appearance: none;
      outline: none;
      cursor: pointer;
      color: color(primary);
      margin: 0 space(xs);
    }

    &--deleted,
    &--added,
    &--copied {
      padding: 27px space(m);
      display: flex;
      align-items: center;

      span {
        text-transform: uppercase;
      }

      button {
        margin-left: space(s);
        margin-right: 0;
      }
    }

    &--sharing {
      padding-right: 160px;
      z-index: 0;

      > * {
        margin-left: space(xs);
      }
    }
  }

  &--contacts &__media,
  &--members &__media {
    img {
      width: 78px;
      height: 78px;
      border-radius: 78px;
    }
  }
}
