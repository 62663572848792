.event-intro {
  position: relative;

  &__wrapper {
    border-top: 1px solid color('neutral', 90);
    border-bottom: 1px solid color('neutral', 90);
    padding: space(m) 0;
    display: flex;
    flex-direction: column;

    @include mq(m) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__date {
    color: color(primary);
  }

  &__application-anchor {
    margin-top: space(m);
  }

  &__location {
    position: relative;
    margin-top: space(l);
    padding-left: space(m);
    flex-shrink: 0;

    @include mq(m) {
      margin-top: 0;
      margin-left: space(l);
      padding: 0 space(m);
    }

    &-icon {
      position: absolute;
      top: 4px;
      left: -4px;
    }
  }
}
