.facet-filter-region {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: space(xl);

  @include mq(m) {
    flex-wrap: nowrap;
  }

  &__location {
    flex-shrink: 0;
    margin-top: space(s);

    @include mq(m) {
      margin-top: space(xs);
    }
  }

  &__search {
    order: -1;
    position: relative;
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;

    svg {
      pointer-events: none;
    }

    @include mq(m) {
      order: unset;
      border-radius: 60px;
      height: 80px;
    }

    &-input {
      border: 1px solid color(primary);
      width: 100%;
      height: 100%;
      border-radius: 42px;
      padding: 0 120px 0 space(l);
      @include typography(form-label);

      @include mq(m) {
        border-radius: 60px;
        padding: 0 120px 0 space(xl);
      }

      &::placeholder {
        text-transform: uppercase;
      }
    }

    &-input-icon {
      position: absolute;
      color: color(primary);
      left: space(s);

      @include mq(m) {
        left: space(m);
      }
    }

    &-select {
      appearance: none;
      border: 1px solid color(primary);
      background: color(neutral, 10);
      height: 100%;
      border-radius: 42px;
      margin-left: -118px;
      padding: 0 space(l) 0 space(m);
      @include typography(button);
      position: absolute;
      right: 0;
      top: 100%;
      margin-top: space(s);
      color: color(primary);
      text-transform: uppercase;

      @include mq(m) {
        position: relative;
        right: unset;
        top:  unset;
        margin-top: 0;
        border-radius: 60px;
        padding: 0 space(l) 0 space(m);
      }
    }

    &-select-icon {
      position: absolute;
      color: color(primary);
      right: space(s);
      top: calc(100% + 32px);

      @include mq(m) {
        top: auto;
        right: 20px;
      }
    }
  }
}
